import React, { useState } from "react";
import { InputWithLabel } from "../../components/utils/utils";
import "./login.css";

import { useDispatch } from "react-redux";
import { ReactComponent as Error } from "../../assets/icons/error-icon.svg";
import { ReactComponent as Logo } from "../../assets/logo-dark.svg";
import { loginUserAction } from "../../redux/user-reducer";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Checked } from "../../assets/icons/remember-icon.svg";
import { mainApi } from "../../components/utils/main-api";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = () => {
    mainApi
      .loginAction({
        username: login,
        password: password,
      })
      .then((token) => {
        if (token) {
  
          mainApi
            .checkAccess(token)
            .then(() => {
              console.log("Доступ подтверждён");
              dispatch(loginUserAction({ is_logged: true }));
              localStorage.setItem("token", token);
              navigate("/analytics");
            })
            .catch((error) => {
              if (error?.detail) {
                setErrorMessage(error.detail);
              } else {
                setErrorMessage("Произошла ошибка проверки доступа.");
              }
              console.error("Ошибка проверки доступа:", error);
              dispatch(loginUserAction({ is_logged: false }));
              setError(true);
            });
        } else {
          setErrorMessage("Неверный логин или пароль.");
          setError(true);
        }
      })
      .catch((error) => {
        dispatch(loginUserAction({ is_logged: false }));
        console.error("Ошибка входа:", error);
        setErrorMessage("Неверный логин или пароль.");
        setError(true);
      });
  };

  return (
    <div className="login_page">
      <div className="login_modal">
        <div className="login_logo">
          <Logo />
        </div>
        <div className="logo_form  add_employe_input">
          <InputWithLabel
            id="user_login"
            label="Логин пользователя"
            value={login}
            event={setLogin}
          />
          <InputWithLabel
            id="user_password"
            label="Пароль пользователя"
            value={password}
            event={setPassword}
          />
        </div>
        <div className={error ? "login_error" : "login_error is_error"}>
          <Error />
          <p>{errorMessage || "Неверный логин или пароль."}</p>
        </div>
        <div className="login_btn_block">
          <button className="login_btn main_btn_template" onClick={handleLogin}>
            Войти
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
