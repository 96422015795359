import React from "react";
import "./case-category.css";
import { InputWithLabel } from "../../components/utils/utils";
import { useState } from "react";
import { ReactComponent as ArrowBackIcon } from "../../assets/icons/arrow-back.svg";
import { NavLink } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { mainApi } from "../../components/utils/main-api";
import { useEffect } from "react";
import Snacbar from "../../components/snackbar/snackbar";
import useDebounce from "../../hooks/useDebounce.ts";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function CaseCategory() {
  const [value, setValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [updatingCategory, setUpdatingCategory] = useState();
  const [isSnackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [search, setSearch] = useState('');
  const [position, setPosition] = useState(0);

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText) => {
    setSnackbarVisible(true);
    setSnackbarText(snackText);
    setTimeout(() => {
      setSnackbarVisible(false);
    }, 2000);
  };

  const getCategory = (search) => {
    mainApi
      .getCaseCategoryAction(search)
      .then((res) => {
        const sortedCategories = res.results.sort((a, b) => a.sequence - b.sequence);
        setCategories(sortedCategories);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getCategory(search);
  }, [debouncedSearch]);

  const saveCategory = () => {
    mainApi
      .setCaseCategoryAction({
        name: value,
        sequence: categories.length + 1,
      })
      .then((res) => {
        snackbarActions("Категория сохранена!");
        setValue("");
        setPosition(0);
        getCategory(search);
        setUpdatingCategory();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const deleteCategory = (id) => {
    mainApi
      .deleteCaseCategoryAction(id)
      .then((res) => {
        getCategory(search);
        snackbarActions("Категория удалена!");
        setUpdatingCategory();
      })
      .catch((error) => {
        console.log("error", error);
      });

    snackbarActions("Категория удалена!");
    setUpdatingCategory();

    setTimeout(() => {
      getCategory(search);
    }, 1200);
  };
  const updateCategory = () => {
    mainApi
      .updateCaseCategoryAction(
        {
          name: value,
          sequence: position,
        },
        updatingCategory.category_id
      )
      .then((res) => {
        snackbarActions("Категория изменена!");
        setUpdatingCategory("");
        setPosition(0);
        getCategory(search);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const setUpdating = (data) => {
    setUpdatingCategory(data);
    setValue(data.name);
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedCategories = Array.from(categories);
    const [movedItem] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, movedItem);

    const updatedCategories = reorderedCategories.map((cat, index) => ({
      ...cat,
      sequence: index + 1,
    }));

    setCategories(updatedCategories);

    updatedCategories.forEach((category) => {
      mainApi
        .updateCaseCategoryAction(
          { name: category.name, sequence: category.sequence },
          category.category_id
        )
        .catch((error) => {
          console.error(
            `Ошибка обновления категории ${category.category_id}:`,
            error
          );
        });
    });

  };

  return (
    <>
      {isSnackbarVisible ? (
        <Snacbar visible={isSnackbarVisible} text={snackbarText} />
      ) : (
        ""
      )}
      <div className="template_page category_page">
        <div className="template_page_title">
          <h1>Категории кейса</h1>
          <div className="users_search">
            <SearchIcon />
            <input
              type="text"
              value={search}
              placeholder="Поиск"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="user_line"></div>
        <NavLink to="/cases">
          <div className="back_btn">
            <ArrowBackIcon /> <p>Назад</p>
          </div>
        </NavLink>
        <div className="categories_page">
          {categories && categories.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="categories">
              {(provided) => (
                <div
                  className="categories_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {categories.map((category, index) => (
                    <Draggable
                      key={category.category_id}
                      draggableId={category.category_id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="categories_list_item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <p>
                            {category.name} (Позиция: {category.sequence})
                          </p>
                          <div className="categories_list_item_buttons">
                            <button
                              title="Редактировать"
                              onClick={() => {
                                setUpdatingCategory(category);
                                setValue(category.name);
                                setPosition(category.sequence);
                              }}
                            >
                              ✏️
                            </button>
                            <button
                              title="Удалить"
                              onClick={() => deleteCategory(category.category_id)}
                            >
                              🗑️
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>     
          ) : (
            ""
          )}

          <div className="add_category_block">
            {updatingCategory ? (
              <>
                <div className="add_employe_input add_category_input">
                  <InputWithLabel
                    id="case_category"
                    label="Категория"
                    value={value}
                    event={setValue}
                  />
                </div>
                <div className="add_employe_input add_category_input">
                  <InputWithLabel
                    id="case_category_position"
                    label="Позиция"
                    value={position}
                    event={setPosition}
                  />
                </div>
                <button
                  className="main_btn save_cat_btn"
                  onClick={updateCategory}
                >
                  Сохранить изменений
                </button>
              </>
            ) : (
              <>
                <div className="add_employe_input add_category_input">
                  <InputWithLabel
                    id="case_category"
                    label="Категория"
                    value={value}
                    event={setValue}
                  />
                </div>
                {/* <div className="add_employe_input add_category_input">
                  <InputWithLabel
                    id="case_category_position"
                    label="Позиция"
                    value={position}
                    event={setPosition}
                  />
                </div> */}
                <button
                  className="main_btn save_cat_btn"
                  onClick={saveCategory}
                >
                  Сохранить
                </button>
              </>
            )}
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default CaseCategory;
