import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";

import Pagination from "../../components/pagionation/pagination";
import { mainApi } from "../../components/utils/main-api";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import { ReactComponent as SelectedIcon } from "../../assets/icons/selected-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";

function RefOutputs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [refOutputs, setRefOutputs] = useState();
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [sort, setSort] = useState('ref_output_id');
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const aboutRefOutputs = (id) => {
    navigate(`/ref_outputs/${id}`);
  };

  const handleGetRefOutputs = (limit, offset = 0, sort, search) => {
    mainApi
      .getRefOutputs(limit, offset, sort, search)
      .then((res) => {
        setRefOutputs(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleDeleteRefOutput = (id) => {
    mainApi
      .deleteRefOutputs(id)
      .then((res) => {
      })
      .catch((error) => {
      });
    snackbarActions("Выплата удалена!");
    setTimeout(() => {
      handleGetRefOutputs(limit.value, currentPage * limit.value, sort, search);
    }, 1200);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.code_data === data.code_data
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.code_data !== data.code_data));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length == refOutputs.length) {
      setSelected([]);
    } else {
      setSelected([...refOutputs]);
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    handleGetRefOutputs(limit.value, currentPage * limit.value, sort, search);
  }, [currentPage, limit, sort, debouncedSearch]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="template_page employees_page">
        <div className="template_page_title">
          <h1>Выплаты Партнерам</h1>
          <div className="top_cases_actions">
            <NavLink to="/create-ref_outputs">
              <button className="main_btn add_case_btn main_btn_template">
                <p>Добавить выплату</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
          </div>
          <div className="users_search">
            <SearchIcon />
            <input
              type="text"
              value={search}
              placeholder="Поиск"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="template_page_content">
          <div className="cases_wrapper">
            {refOutputs && refOutputs.length ? (
              <>
                <table className="cases_table">
                  <thead>
                    <tr>
                      <td>
                        <button
                          className={
                            sort == "ref_output_id" || sort == "-ref_output_id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'ref_output_id') {
                              setSort('ref_output_id')
                            } 
                            if (sort === 'ref_output_id') {
                              setSort('-ref_output_id')
                            }
                          }}
                        >
                          <p>ID</p>
                          {sort === 'ref_output_id' ? <p>&uarr;</p> : sort === '-ref_output_id' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>Активный</td>
                      <td>
                        <button
                          className={
                            sort == "status" || sort == "-status" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'status') {
                              setSort('status')
                            } 
                            if (sort === 'status') {
                              setSort('-status')
                            }
                          }}
                        >
                          <p>Статус</p>
                          {sort === 'status' ? <p>&uarr;</p> : sort === '-status' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "sum" || sort == "-sum" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'sum') {
                              setSort('sum')
                            } 
                            if (sort === 'sum') {
                              setSort('-sum')
                            }
                          }}
                        >
                          <p>Сумма</p>
                          {sort === 'sum' ? <p>&uarr;</p> : sort === '-sum' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>Имя пользователя</td>
                      <td>
                        <div className="select_all">
                          <div className="is_selected ">
                            {selected.length == refOutputs.length ? (
                              <SelectedIcon onClick={toggleAllDataSelected} />
                            ) : (
                              <div
                                className="not_selected_item"
                                onClick={toggleAllDataSelected}
                              ></div>
                            )}
                          </div>{" "}
                          Выделить все
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {refOutputs && refOutputs.length
                      ? refOutputs.map((refOutput) => (
                        <tr key={refOutput.ref_output_id}>
                          <td>{refOutput.ref_output_id || "-"}</td>
                          <td>
                            {refOutput.active ? "Да" : "Нет"}
                          </td>
                          <td>
                          <div className="cases_table_actions_list">
                            {refOutput.status == "completed" ? (
                                <p>Завершён</p>
                            ) : (
                              ""
                              )}
                              {refOutput.status == "created" ? (
                                <p>Созданный</p>
                            ) : (
                              ""
                              )}
                              {refOutput.status == "canceled" ? (
                                <p>Отмененный</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                          <td>{refOutput.sum}</td>
                          <td>{`${refOutput.user?.username || "-"} : ${refOutput.user?.first_name || "-"} ${refOutput.user?.last_name || "-"}`}</td>
                          <td>
                            <div className="cases_table_actions">
                              <div className="cases_table_actions_list">
                            <div
                              title="посмотреть"
                              className="cases_table_edit"
                              onClick={() => aboutRefOutputs(refOutput.ref_output_id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                          </div>
                              <div className="cases_table_actions_list">
                                <div
                                  title="удалить"
                                  className="cases_table_delete"
                                  onClick={() =>
                                    handleDeleteRefOutput(refOutput.ref_output_id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="is_selected">
                                {selected.some(
                                  (selected) =>
                                    selected.ref_output_id === refOutput.ref_output_id
                                ) ? (
                                  <SelectedIcon
                                    onClick={() => toggleSelected(refOutput)}
                                  />
                                ) : (
                                  <div
                                    className="not_selected_item"
                                    onClick={() => toggleSelected(refOutput)}
                                  ></div>
                                )}
                                </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : ""}
                  </tbody>
                </table>
                <div className="cases_paginations">
                  <Pagination
                    pageCount={Math.ceil(dataLength / limit.value)}
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
                <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
              </>
            ) : (
              <p className="empty_error">Выплаты партнерам отсутствуют</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RefOutputs;
