import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { format } from "date-fns";
import Pagination from "../../components/pagionation/pagination";
import { mainApi } from "../../components/utils/main-api";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as SelectedIcon } from "../../assets/icons/selected-icon.svg";
import avatar from "../../assets/images/avatar.png";
import "./user.css";

function Users() {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState('user_id');
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const aboutUser = (id) => {
    navigate(`/user/${id}`);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.user_id === data.user_id
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.user_id !== data.user_id));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length == users.length) {
      setSelected([]);
    } else {
      setSelected([...users]);
    }
  };

  const getUsers = (limit, offset = 0, sort, search) => {
    mainApi
      .getUsersActions(limit, offset, sort, search)
      .then((res) => {
        setUsers(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getUsers(limit.value, currentPage * limit.value, sort, search);
  }, [currentPage, sort, limit, debouncedSearch]);

  return (
    <div className="template_page users_page">
      <div className="template_page_title">
        <h1>Пользователи</h1>
        <div className="users_search">
          <SearchIcon />
          <input
            type="text"
            value={search}
            placeholder="Поиск"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="template_page_content ">
        <div className="users_page_wrapper">
          {/* <div className="cases_top_togglers">
            <button className="main_btn">
              <p>Все предметы</p>
            </button>
            <button className="main_btn">
              <p>Блогеры</p>
            </button>
            <button className="main_btn">
              <p>Сотрудники</p>
            </button>
          </div> */}

          <div className="user_line"></div>
          {users && users.length ? (
            <>
              <table className="users_table">
                <thead>
                  <tr>
                    <th className="table_user_id_title">
                      <button
                        className={
                          sort == "user_id" || sort == "-user_id" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                          if (sort !== 'user_id') {
                            setSort('user_id')
                          } 
                          if (sort === 'user_id') {
                            setSort('-user_id')
                          }
                        }}
                      >
                        <p>ID</p>
                        {sort === 'user_id' ? <p>&uarr;</p> : sort === '-user_id' ? <p>&darr;</p> : ''}
                      </button>
                    </th>
                    <th className="table_user_avatar_title">Аватар</th>
                    <th className="table_user_name_title">
                      <button
                        className={
                          sort == "username" || sort == "-username" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                          if (sort !== 'username') {
                            setSort('username')
                          } 
                          if (sort === 'username') {
                            setSort('-username')
                          }
                        }}
                      >
                        <p>Имя юзера</p>
                        {sort === 'username' ? <p>&uarr;</p> : sort === '-username' ? <p>&darr;</p> : ''}
                      </button>
                    </th>
                    <th className="table_user_balance_title">
                      <button
                        className={
                          sort == "balance" || sort == "-balance" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                          if (sort !== 'balance') {
                            setSort('balance')
                          } 
                          if (sort === 'balance') {
                            setSort('-balance')
                          }
                        }}
                      >
                        <p>Баланс</p>
                        {sort === 'balance' ? <p>&uarr;</p> : sort === '-balance' ? <p>&darr;</p> : ''}
                      </button>
                    </th>
                    <th className="table_user_deposite_title">
                      <button
                        className={
                          sort == "all_debit" || sort == "-all_debit" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                          if (sort !== 'all_debit') {
                            setSort('all_debit')
                          } 
                          if (sort === 'all_debit') {
                            setSort('-all_debit')
                          }
                        }}
                      >
                        <p>Депозитов</p>
                        {sort === 'all_debit' ? <p>&uarr;</p> : sort === '-all_debit' ? <p>&darr;</p> : ''}
                      </button>
                    </th>
                    <th className="table_user_winrate_title">
                      <button
                        className={
                          sort == "all_output" || sort == "-all_output" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                          if (sort !== 'all_output') {
                            setSort('all_output')
                          } 
                          if (sort === 'all_output') {
                            setSort('-all_output')
                          }
                        }}
                      >
                        <p>Выводы</p>
                        {sort === 'all_output' ? <p>&uarr;</p> : sort === '-all_output' ? <p>&darr;</p> : ''}
                      </button>
                    </th>
                    <th className="table_user_winrate_title">
                      <button
                        className={
                          sort == "winrate" || sort == "-winrate" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                          if (sort !== 'winrate') {
                            setSort('winrate')
                          } 
                          if (sort === 'winrate') {
                            setSort('-winrate')
                          }
                        }}
                      >
                        <p>Винрейт</p>
                        {sort === 'winrate' ? <p>&uarr;</p> : sort === '-winrate' ? <p>&darr;</p> : ''}
                      </button>
                    </th>
                    <th className="table_user_winrate_title">Дата регистрации</th>
                    <td className="users_select">
                      <div className="select_all">
                        <div className="is_selected ml_55px">
                          {selected.length == users.length ? (
                            <SelectedIcon onClick={toggleAllDataSelected} />
                          ) : (
                            <div
                              className="not_selected_item"
                              onClick={toggleAllDataSelected}
                            ></div>
                          )}
                        </div>{" "}
                        Выделить все
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {users
                    ? users.map((user) => (
                        <tr key={user.user_id}>
                          <td className="table_user_id_row">
                            <p>{user.user_id}</p>
                          </td>
                          <td className="table_user_avatar_row">
                            <img src={user.image || avatar} alt="" />
                          </td>
                          <td className="table_user_name_row">
                            <p>{user.username}</p>
                          </td>

                          <td className="table_user_balance_row">
                            <p>{user.balance.toFixed(2)} ₽</p>
                          </td>
                          <td className="table_user_deposite_row">
                            <p>{user.all_debit.toFixed(2)} ₽</p>
                          </td>
                          <td className="table_user_deposite_row">
                            <p>{user.all_output.toFixed(2)} ₽</p>
                          </td>
                          <td className="table_user_winrate_row">
                            <p>{user.winrate.toFixed(2)}%</p>
                          </td>
                          <td className="table_user_winrate_row">
                            {format(user.date_joined, "yyyy-MM-dd HH:mm:ss")}
                          </td>

                          <td>
                            <div className="cases_table_actions">
                              <div className="cases_table_actions_list">
                                <div
                                  title="редактировать"
                                  className="cases_table_edit"
                                  onClick={() => aboutUser(user.user_id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.99902 17.2512V21.0012H6.74902L17.809 9.94125L14.059 6.19125L2.99902 17.2512ZM20.709 7.04125C21.099 6.65125 21.099 6.02125 20.709 5.63125L18.369 3.29125C17.979 2.90125 17.349 2.90125 16.959 3.29125L15.129 5.12125L18.879 8.87125L20.709 7.04125Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="is_selected ">
                                {selected.some(
                                  (selected) =>
                                    selected.user_id === user.user_id
                                ) ? (
                                  <SelectedIcon
                                    onClick={() => toggleSelected(user)}
                                  />
                                ) : (
                                  <div
                                    className="not_selected_item"
                                    onClick={() => toggleSelected(user)}
                                  ></div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
              <div className="cases_paginations">
                <Pagination
                  pageCount={Math.ceil(dataLength / limit.value)}
                  onPageChange={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
              <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;
