import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUserAction } from "../src/redux/user-reducer";
import { mainApi } from "../src/components/utils/main-api";

import NavBar from "./components/nav-bar/nav-bar";
import SideBar from "./components/side-bar/side-bar";
import AddEmploye from "./pages/add-employe/add-employe";
import EditEmployee from "./pages/add-employe/edit-employee";
import Analytics from "./pages/analytics/analytics";
import CaseCategory from "./pages/case-categories/case-category";
import Cases from "./pages/cases/cases";
import Competitions from "./pages/competitions/competitions";
import CreateCompetitons from "./pages/competitions/create-competitons";
import ConclusionPage from "./pages/conclusion-page/conclusion-page";
import Conclusions from "./pages/conclusions/conclusions";
import CreateCase from "./pages/create-case/create-case";
import EditCase from "./pages/create-case/edit-case";
import CreateItem from "./pages/create-items/create-item";
import EditItem from "./pages/create-items/edit-item";
import CreatePromocode from "./pages/create-promocode/create-promoode";
import EditPromocode from "./pages/create-promocode/edit-promocode";
import Employees from "./pages/employees/employees";
import Items from "./pages/items/items";
import LoginPage from "./pages/login/login";
import Payments from "./pages/payments/payments";
import Positions from "./pages/positions/positions";
import Promocodes from "./pages/promocodes/promocodes";
import RarityCategory from "./pages/rarity-category/rarity-category";
import UpdateRarity from "./pages/rarity-category/update-rarity";
import ReviewPage from "./pages/review-page/review-page";
import Reviews from "./pages/reviews/reviews";
import Settings from "./pages/settings/settings";
import Support from "./pages/support/support";
import UserPage from "./pages/user-page/user-page";
import Users from "./pages/users/users";
import CreateRarity from "./pages/rarity-category/create-rarity";
import Conditions from "./pages/conditions/conditions";
import CreateCondion from "./pages/conditions/create-condition";
import EditCondion from "./pages/conditions/edit-condition";
import EditUserPage from "./pages/user-page/edit-user-page";
import EditCompetitons from "./pages/competitions/edit-competitons";
import PaymentPage from "./pages/payments/payment-page";
import Snacbar from "./components/snackbar/snackbar";
import RefLinks from "./pages/ref-links/ref-links";
import EditRefLinks from "./pages/ref-links/edit-ref-links";
import RefOutputs from "./pages/ref_outputs/ref_outputs";
import CreateRefOutput from "./pages/ref_outputs/create-ref_outputs";
import EditRefOutputs from "./pages/ref_outputs/edit-ref_outputs copy";


function App() {
  const isLogged = useSelector((user) => user.user.user.is_logged);
  const [loading, setLoading] = useState(true);
  const snackbar = useSelector((state) => state.snackbar);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(loginUserAction({ is_logged: true }));
    } else {
      dispatch(loginUserAction({ is_logged: false }));
      navigate("/login");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      mainApi
        .checkAccess(token)
        .then(() => {
          dispatch(loginUserAction({ is_logged: true }));
        })
        .catch(() => {
          localStorage.removeItem("token");
          dispatch(loginUserAction({ is_logged: false }));
          navigate("/login");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(loginUserAction({ is_logged: false }));
      navigate("/login");
      setLoading(false);
    }
  }, [dispatch, navigate]);

  return (
    <>
    {!isLogged ? (
        <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    ) : (
      <div className="site_content">
        <NavBar />
        <div className="page_wrapper">
          <div className="side_bar_wrapper">
            <SideBar />
          </div>
          {snackbar.show ? (
            <Snacbar text={snackbar.text} snackbar={snackbar}/>
          ) : (
            ""
          )}
          <div className="page_content">
            <Suspense fallback={"loading....."}>
              <Routes>
                <Route path="/" element={<Analytics />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user/:user" element={<UserPage />} />
                <Route path="/edit-user/:user" element={<EditUserPage />} />

                <Route path="/employees" element={<Employees />} />
                <Route path="/cases" element={<Cases />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/payment/:id" element={<PaymentPage />} />
               
                <Route path="/reviews" element={<Reviews />} />
                <Route path="/review/:review" element={<ReviewPage />} />
                <Route path="/positions" element={<Positions />} />
                <Route path="/create-item" element={<CreateItem />} />
                <Route path="/edit-item/:item" element={<EditItem />} />
                <Route path="/rarity" element={<RarityCategory />} />
                <Route path="/update-rarity/:item" element={<UpdateRarity />} />

                <Route path="/support" element={<Support />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/conclusions" element={<Conclusions />} />
           
                <Route
                  path="/conclusion/:conclusion"
                  element={<ConclusionPage />}
                />
                <Route path="/promocodes" element={<Promocodes />} />
                <Route path="/create-promocode" element={<CreatePromocode />} />
                <Route path="/edit-promocode/:id" element={<EditPromocode />} />
                <Route path="/items" element={<Items />} />
                <Route path="/add-employee" element={<AddEmploye />} />
                <Route
                  path="/edit-employee/:employee"
                  element={<EditEmployee />}
                />
                <Route path="/competitons" element={<Competitions />} />
                <Route
                  path="/create-competiton"
                  element={<CreateCompetitons />}
                />
                <Route path="/competitons/:id" element={<EditCompetitons />} />


                <Route path="/login" element={<LoginPage />} />
                <Route path="/create-case" element={<CreateCase />} />

                <Route path="/edit-case/:case" element={<EditCase />} />
                <Route path="/cases-category" element={<CaseCategory />} />
                <Route path="/create-rarity" element={<CreateRarity />} />
                <Route path="/conditions" element={<Conditions />} />
                <Route path="/create-condition" element={<CreateCondion />} />
                <Route path="/edit-condition/:item" element={<EditCondion />} />

                <Route path="/ref-links" element={<RefLinks />} />
                <Route path="/edit-ref-links/:id" element={<EditRefLinks />} />
                <Route path="/ref_outputs" element={<RefOutputs />} />
                <Route path="/create-ref_outputs" element={<CreateRefOutput />} />
                <Route path="/ref_outputs/:id" element={<EditRefOutputs />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
       )}
    </>
  );
}

export default App;
