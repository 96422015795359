import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {useDispatch} from "react-redux";

import { mainApi } from "../../components/utils/main-api";
import {snackbarAction, snackbarClearAction} from "../../redux/snackbar-reducer";

import { ReactComponent as ArrowBackIcon } from "../../assets/icons/arrow-back.svg";
import "react-calendar/dist/Calendar.css";

function EditRefLinks() {
  const params = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [active, setActive] = useState(false);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const updateRefLink = () => {
    mainApi
      .updateRefLinkAction(
        {
          code_data: id,
          active: active,
        },
        params.id
      )
      .then((res) => {
        snackbarActions("Реф. система обновлёна");
      })
      .catch((error) => {
        console.log("error", error);
        snackbarActions("Ошибка обновления Реф. системы", error);
      });
  };

  const getRefLink = () => {
    mainApi
      .getRefLinkAction(params.id)
      .then((res) => {
        setId(res.code_data);
        setActive(res.active);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getRefLink();
  }, [params.id]);

  return (
    <div className="template_page create_item_page">
      <div className="template_page_title">
        <h1>Редактировать Реф. систему</h1>
      </div>
      <div className="user_line"></div>
      <NavLink to="/ref-links">
        <div className="back_btn">
          <ArrowBackIcon /> <p>Назад</p>
        </div>
      </NavLink>
      <div className="create_item_wrapper create_competition_wrapper">
        <div className="personal_information_block">
          <p>ID</p>
          <input
            type="text"
            placeholder="0"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </div>
        <div className="user_checkboxes contest_conds">
          <div className="user_checkbox">
            <p>Активный:</p>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </div>
        </div>
        <div className="admin_actions case_actions">
          <button
            className="create_admin_btn main_btn_template_green"
            onClick={updateRefLink}
          >
            Сохранить
          </button>
          <NavLink to="/competitons">
            <button className="undo_create main_btn_template_border">
              Отменить
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default EditRefLinks;
