import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { mainApi } from "../../components/utils/main-api";
import Snacbar from "../../components/snackbar/snackbar";
import Pagination from "../../components/pagionation/pagination";
import { format } from "date-fns";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as SelectedIcon } from "../../assets/icons/selected-icon.svg";
import { ReactComponent as TopIcon } from "../../assets/icons/top.svg";
import "../cases/cases.css";

const categories = {
  crystal: "Кристалл",
  blessing: "Благословение",
  ghost_item: "Призрачный пердмет",
};

function Items() {
  const navigate = useNavigate();

  const [isSnackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [items, setItems] = useState("");
  const [rarity, setRarity] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [activeFilter, setActiveFilter] = useState("");
  const [selected, setSelected] = useState([]);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText) => {
    setSnackbarVisible(true);
    setSnackbarText(snackText);
    setTimeout(() => {
      setSnackbarVisible(false);
    }, 2000);
  };

  const getItems = (limit, offset = 0, activeFilter, sort, search) => {
    mainApi
      .getItemsAction(limit, offset, activeFilter, sort, search)
      .then((res) => {
        setItems(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getRarityList = () => {
    mainApi
      .getRarytyListActions()
      .then((res) => {
        setRarity(res.results);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const deleteItem = (id) => {
    mainApi
      .deleteItemAction(id)
      .then((res) => {
        snackbarActions("Предмет удалён!");
        getItems(limit.value, currentPage * limit.value, activeFilter, sort, search);
      })
      .catch((error) => {
        console.log("error", error);
      });
    setTimeout(() => {
      getItems(limit.value, currentPage * limit.value, activeFilter, sort, search);
      snackbarActions("Предмет удалён!");
    }, 1500);
  };

  const deleteItems = () => {
    const bulkDestroy = selected.map((el) => el.item_id)
    mainApi
      .bulkDestroyItemsAction(bulkDestroy)
      .then((res) => {
        getItems(limit.value, currentPage * limit.value, activeFilter, sort, search);
        snackbarActions(res.message);
        setSelected([]);
      })
      .catch((error) => {
        snackbarActions("Ошибка", error);
      });
  };

  const editItem = (id) => {
    navigate(`/edit-item/${id}`);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.item_id === data.item_id
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.item_id !== data.item_id));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length == items.length) {
      setSelected([]);
    } else {
      setSelected([...items]);
    }
  };

  const getItemType = (type) => {
    return categories[type];
  };

  const filterItems = (type) => {
    setActiveFilter(type);
    setCurrentPage(0);
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getRarityList();
  }, []);

  useEffect(() => {
    getItems(limit.value, currentPage * limit.value, activeFilter, sort, search);
  }, [currentPage, activeFilter, sort, limit, debouncedSearch]);

  return (
    <>
      {isSnackbarVisible ? (
        <Snacbar visible={isSnackbarVisible} text={snackbarText} />
      ) : (
        ""
      )}
      <div className="template_page employees_page">
        <div className="template_page_title">
          <h1>Предметы</h1>
          <div className="top_cases_actions">
            <NavLink to="/rarity">
              <button className="main_btn add_categories_btn main_btn_template_orange">
                <p>Категории редкости</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
            <NavLink to="/create-item">
              <button className="main_btn add_case_btn main_btn_template">
                <p>Добавить предмет</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
        <div className="template_page_content">
          <div className="cases_wrapper">
            <div className="cases_top_togglers">
              <button
                className={
                  activeFilter == ""
                    ? "main_btn top_active_filter"
                    : "main_btn"
                }
                onClick={() => filterItems("")}
              >
                <p>Все кейсы</p>
              </button>
              {rarity && rarity.length
                ? rarity.map((rarity) => (
                    <button
                      className={
                        activeFilter == rarity.rarity_id
                          ? "main_btn top_active_filter"
                          : "main_btn"
                      }
                      key={rarity.name}
                      onClick={() => filterItems(rarity.rarity_id)}
                    >
                      <p>{rarity.name}</p>
                    </button>
                  ))
                : ""}
            </div>
            <div className="cases_top_actions">
              {selected.length !==0 && (
                <button
                  className="main_btn main_btn_template_red"
                  onClick={deleteItems}
                >
                  <p>Массовое удаление</p>
                </button>
              )}
              <div className="users_search">
                <SearchIcon />
                <input
                  type="text"
                  value={search}
                  placeholder="Поиск"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="user_line"></div>

            {items && items.length ? (
              <>
                <table className="cases_table">
                  <thead>
                    <tr>
                      <td>
                        <button
                          className={
                            sort == "item_id" || sort == "-item_id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'item_id') {
                              setSort('item_id')
                            } 
                            if (sort === 'item_id') {
                              setSort('-item_id')
                            }
                          }}
                        >
                          <p>ID предмета</p>
                          {sort === 'item_id' ? <p>&uarr;</p> : sort === '-item_id' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "name" || sort == "-name" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'name') {
                              setSort('name')
                            } 
                            if (sort === 'name') {
                              setSort('-name')
                            }
                          }}
                        >
                          <p>Название</p>
                          {sort === 'name' ? <p>&uarr;</p> : sort === '-name' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "rarity_category" || sort == "-rarity_category" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'rarity_category') {
                              setSort('rarity_category')
                            } 
                            if (sort === 'rarity_category') {
                              setSort('-rarity_category')
                            }
                          }}
                        >
                          <p>Категория</p>
                          {sort === 'rarity_category' ? <p>&uarr;</p> : sort === '-rarity_category' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "type" || sort == "-type" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'type') {
                              setSort('type')
                            } 
                            if (sort === 'type') {
                              setSort('-type')
                            }
                          }}
                        >
                          <p>Тип</p>
                          {sort === 'type' ? <p>&uarr;</p> : sort === '-type' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">
                        <button
                          className={
                            sort == "price" || sort == "-price" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'price') {
                              setSort('price')
                            } 
                            if (sort === 'price') {
                              setSort('-price')
                            }
                          }}
                        >
                          <p>Цена</p>
                          {sort === 'price' ? <p>&uarr;</p> : sort === '-price' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">
                        <button
                          className={
                            sort == "purchase_price" || sort == "-purchase_price" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'purchase_price') {
                              setSort('purchase_price')
                            } 
                            if (sort === 'purchase_price') {
                              setSort('-purchase_price')
                            }
                          }}
                        >
                          <p>Закупочная цена</p>
                          {sort === 'purchase_price' ? <p>&uarr;</p> : sort === '-purchase_price' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">
                        <button
                          className={
                            sort == "created_at" || sort == "-created_at" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'created_at') {
                              setSort('created_at')
                            } 
                            if (sort === 'created_at') {
                              setSort('-created_at')
                            }
                          }}
                        >
                          <p>Дата создания</p>
                          {sort === 'created_at' ? <p>&uarr;</p> : sort === '-created_at' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <div className="select_all">
                          <div className="is_selected ">
                            {selected.length == items.length ? (
                              <SelectedIcon onClick={toggleAllDataSelected} />
                            ) : (
                              <div
                                className="not_selected_item"
                                onClick={toggleAllDataSelected}
                              ></div>
                            )}
                          </div>{" "}
                          Выделить все
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.length
                      ? items.map((item) => (
                          <tr key={item.item_id}>
                            <td>{item.item_id}</td>
                            <td>{item.name}</td>
                            <td>
                              {item.rarity_category &&
                                item.rarity_category.name}
                            </td>
                            <td>{getItemType(item.type)}</td>
                            <td className="tac">{item.price} ₽</td>
                            <td className="tac">{item.purchase_price} ₽</td>

                            <td className="tac">
                              {format(item.created_at, "yyyy-MM-dd HH:mm:ss")}
                            </td>
                            <td>
                              <div className="cases_table_actions">
                                <div className="cases_table_actions_list">
                                  <div
                                    title="редактировать"
                                    className="cases_table_edit"
                                    onClick={() => editItem(item.item_id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M2.99902 17.2512V21.0012H6.74902L17.809 9.94125L14.059 6.19125L2.99902 17.2512ZM20.709 7.04125C21.099 6.65125 21.099 6.02125 20.709 5.63125L18.369 3.29125C17.979 2.90125 17.349 2.90125 16.959 3.29125L15.129 5.12125L18.879 8.87125L20.709 7.04125Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>

                                  <div
                                    title="удалить"
                                    className="cases_table_delete"
                                    onClick={() => deleteItem(item.item_id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div className="is_selected ">
                                  {selected.some(
                                    (selected) =>
                                      selected.item_id === item.item_id
                                  ) ? (
                                    <SelectedIcon
                                      onClick={() => toggleSelected(item)}
                                    />
                                  ) : (
                                    <div
                                      className="not_selected_item"
                                      onClick={() => toggleSelected(item)}
                                    ></div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
                <div className="cases_paginations">
                  <Pagination
                    pageCount={Math.ceil(dataLength / limit.value)}
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
                <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
              </>
            ) : (
              <p className="empty_error">Предметы отсутствуют</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Items;
