import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { mainApi } from "../../components/utils/main-api";
import {snackbarAction, snackbarClearAction} from "../../redux/snackbar-reducer";

import { ReactComponent as ArrowBackIcon } from "../../assets/icons/arrow-back.svg";
import "react-calendar/dist/Calendar.css";

function EditRefOutputs() {
  const dispatch = useDispatch();
  const params = useParams();

  const [payment, setPayment] = useState({});

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const approveCanceled = () => {
    mainApi
      .approveCanceledAction(params.id)
      .then((res) => {
        snackbarActions("Вывод отменили");
      })
      .catch((error) => {
        snackbarActions("Ошибка", error);
      });
  };

  const approveCompleted = () => {
    mainApi
      .approveCompletedAction(params.id)
      .then((res) => {
        snackbarActions("Успешно вывели");
      })
      .catch((error) => {
        snackbarActions("Ошибка", error);
      });
  };

  const getOutput = () => {
    mainApi
      .getRefOutputAction(params.id)
      .then((res) => {
        setPayment(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getOutput();
  }, [params]);

  return (
    <div className="template_page create_item_page">
      <div className="template_page_title">
        <h1>Выплата партнеру</h1>
      </div>
      <div className="user_line"></div>
      <NavLink to="/ref_outputs">
        <div className="back_btn">
          <ArrowBackIcon /> <p>Назад</p>
        </div>
      </NavLink>
      <div className="create_item_wrapper create_competition_wrapper">
        <h2 className="create_competition_wrapper_block_title">
          Информация
        </h2>
        <div className="case_input_temp">
          <p>ID</p>
          <input
            type="text"
            placeholder="Введите ID"
            value={payment.id}
            readOnly
          />
        </div>
        <div className="case_input_temp">
          <p>Тип платежа</p>
          <input
            type="text"
            placeholder="0"
            value={payment.type === 'card' ? 'Вывод на банковскую карту' : payment.type === 'cryptocurrency' ? 'Вывод на криптокошелек' : 'Вывод по СБП' }
            readOnly
          />
        </div>
        <div className="case_input_temp">
          <p>Сумма вывода</p>
          <input
            type="text"
            placeholder="0"
            value={payment.sum || '-'}
            readOnly
          />
        </div>
        <div className="case_input_temp">
          <p>Номер телефона</p>
          <input
            type="text"
            placeholder="-"
            value={payment.phone || '-'}
            readOnly
          />
        </div>
        <div className="case_input_temp">
          <p>Номер карты</p>
          <input
            type="text"
            value={payment.card_number || '-'}
            readOnly
          />
        </div>
        <div className="case_input_temp">
          <p>Номер криптокошелька</p>
          <input
            type="text"
            value={payment.crypto_number || '-'}
            readOnly
          />
        </div>
        <div className="case_input_temp">
          <p>Комментарий</p>
          <input
            type="text"
            value={payment.comment || '-'}
            readOnly
          />
        </div>
        <div className="user_checkboxes contest_conds">
          <div className="user_checkbox">
            <p>Активный:</p>
            <input
              type="checkbox"
              checked={payment.active}
              readOnly
            />
          </div>
        </div>
        <div className="case_input_temp">
          <p>Статус платежа</p>
          <input
            type="text"
            placeholder="0"
            value={payment.status === 'created' ? 'Созданный' : payment.status === 'completed' ? 'Завершенный' : 'Отмененный' }
            readOnly
          />
        </div>
        <div className="admin_actions case_actions">
          <button
            className="create_admin_btn main_btn_template_green"
            onClick={approveCanceled}
          >
            Вывод отменили админы
          </button>
          <button
            className="create_admin_btn main_btn_template_green"
            onClick={approveCompleted}
          >
            Успешно вывели
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditRefOutputs;
