import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import { mainApi } from "../utils/main-api";
import { format } from "date-fns";

import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";
import "./chart.css";
import { useMemo } from "react";

function AnalyticsChart({ selectedTime }) {

  const [loading, setLoading] = useState(false);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Доходы",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "rgb(57, 181, 74)",
      },
      {
        name: "Расходы",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "rgb(235, 30, 35)",
      },
      {
        name: "Открыто кейсов",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "rgb(243, 172, 14)",
      },
      //{
      //  name: "Доходы от кейсов",
      //  data: [0, 0, 0, 0, 0, 0, 0],
      //  color: "rgb(166, 0, 255)",
      //},
      {
        name: "Чистая прибыль",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "#358ed7",
      },
      {
        name: "Новые пользователи",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "#fb8a01",
      },
      {
        name: "Средний чек",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "#00FFFF",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 100000000,
                to: -100000000,
                color: "rgb(173, 173, 173)",
              },
            ],
          },
          columnWidth: "10px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return y.toFixed(2);
          },
        },
      },

      xaxis: {
        type: "datetime",
        categories: [

        ],
        labels: {
          format: "MM/dd",
          style: {
            colors: "rgb(173, 173, 173)",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${Number(value)}`;
            // return `${Number(value).toFixed(2)}`;
          },
        },
      },
      legend: {
        position: "right",
        fontFamily: "Inter",
        fontSize: "12px",
        markers: {
          width: 20,
          height: 20,
          radius: 3,
        },
        itemMargin: {
          vertical: 10,
        },
      },
    },
  });

  const [chartCasesIncomeData, setChartCasesIncomeData] = useState({
    series: [
      {
        name: "Доходы от кейсов",
        data: [0, 0, 0, 0, 0, 0, 0],
        color: "rgb(166, 0, 255)",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 100000000,
                to: -100000000,
                color: "rgb(173, 173, 173)",
              },
            ],
          },
          columnWidth: "10px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return y.toFixed(2);
          },
        },
      },
      xaxis: {
        type: "category",
        categories: [

        ],
        labels: {
          style: {
            colors: "rgb(173, 173, 173)",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${Number(value)}`;
          },
        },
      },
      legend: {
        show: false,
      },
    },
  });

  const updateChartData = (newIncomeData, newOutlayData, newCasesData, casesIncome, total, users, check) => {
    const updatedIncomeData = newIncomeData.map((entry) => entry.income);
    const updatedOutlayData = newOutlayData.map((entry) => entry.outlay);
    const updatedCasesData = newCasesData.map((entry) => entry.count);
    const updatedTotalIncomeData = total.map((entry) => entry.profit);
    const updatedUsersData = users.map((entry) => entry.count);
    const updatedAverageCheckData = check.map((entry) => entry.check);

    // Обновляем данные графика
    setChartData({
      ...chartData,
      series: [
        { ...chartData.series[0], data: updatedIncomeData },
        { ...chartData.series[1], data: updatedOutlayData },
        { ...chartData.series[2], data: updatedCasesData },
        { ...chartData.series[3], data: updatedTotalIncomeData },
        { ...chartData.series[4], data: updatedUsersData },
        { ...chartData.series[5], data: updatedAverageCheckData },
      ],
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories: newIncomeData.map((entry) => entry.date),
        },
      },
    });

    const updatedCasesIncomeData = casesIncome.map((entry) => entry.income);
    setChartCasesIncomeData({
      ...chartCasesIncomeData,
      series: [
        { data: updatedCasesIncomeData },
      ],
      options: {
        ...chartCasesIncomeData.options,
        xaxis: {
          ...chartCasesIncomeData.options.xaxis,
          categories: casesIncome.map((entry) => entry.case_name),
        },
      },
    });
    setLoading(false);
  };

  const formatDate = (date) => {
    return format(date, "yyyy-MM-dd");
  };

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      mainApi.getGraphsIncomeAction(
        formatDate(selectedTime[0]),
        formatDate(selectedTime[1])
      ),
      mainApi.getGraphsOutlayAction(
        formatDate(selectedTime[0]),
        formatDate(selectedTime[1])
      ),
      mainApi.getGraphsCasesAction(
        formatDate(selectedTime[0]),
        formatDate(selectedTime[1])
      ),
      mainApi.getGraphsCasesIncomeAction(
        formatDate(selectedTime[0])
      ),
      mainApi.getGraphsTotalIncomeAction(
        formatDate(selectedTime[0]),
        formatDate(selectedTime[1])
      ),
      mainApi.getGraphsRegusersAction(
        formatDate(selectedTime[0]),
        formatDate(selectedTime[1])
      ),
      mainApi.getGraphsAverageCheckAction(
        formatDate(selectedTime[0]),
        formatDate(selectedTime[1])
      ),
    ])
      .then(([incomeRes, outlayRes, casesRes, casesIncome, total, users, check]) => {
        updateChartData(incomeRes, outlayRes, casesRes, casesIncome, total, users, check);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedTime]);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className="chart_loading">
          <Spinner />
      </div>
      )
    }
    return (
      <>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={370}
        />
        <p className="chart_date">График за день: {formatDate(selectedTime[0])}</p>
        <ReactApexChart
          options={chartCasesIncomeData.options}
          series={chartCasesIncomeData.series}
          type="bar"
          height={370}
        />
      </>
    )
  }, [chartCasesIncomeData.options, chartCasesIncomeData.series, chartData.options, chartData.series, loading, selectedTime])

  return content;
}

export default AnalyticsChart;
