import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";

import Pagination from "../../components/pagionation/pagination";
import { mainApi } from "../../components/utils/main-api";
import { format } from "date-fns";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import { ReactComponent as SelectedIcon } from "../../assets/icons/selected-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import "./payments.css";

function Payments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [payments, setPayments] = useState([]);
  const [selected, setSelected] = useState([]);
  const [paymentsDataLength, setPaymentsDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [activeFilter, setActiveFilter] = useState("");
  const [typePayments, setTypePayments] = useState("");
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [sort, setSort] = useState('created_at');
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const aboutPayment = (id) => {
    navigate(`/payment/${id}`);
  };

  const aboutUser = (id) => {
    navigate(`/user/${id}`);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.id === data.id
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.id !== data.id));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length == payments.length) {
      setSelected([]);
    } else {
      setSelected([...payments]);
    }
  };

  const refresh = (limit, offset = 0, activeFilter, typePayments, sort, search) => {
    mainApi
      .getPaymentsAction(limit, offset, activeFilter, typePayments, sort, search)
      .then((res) => {
        setPayments(res.results);
        setPaymentsDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getStatus = (status) => {
    if (status == "create") {
      return "Создан";
    } else if (status == "expired") {
      return "Отменен";
    } else if (status == "success") {
      return "Оплачен";
    } else if (status == "manually approved") {
      return "Одобрен вручную";
    }
  };

  const filterItems = (type) => {
    setActiveFilter(type);
    setCurrentPage(0);
  };

  const filterTypePayments = (type) => {
    setTypePayments(type);
    setCurrentPage(0);
  };

  const approveOutput = (order_id) => {
    mainApi
      .approvePaymentAction(order_id)
      .then((res) => {
        snackbarActions("Платежь одобрен");
      })
      .catch((error) => {
        snackbarActions("Ошибка одобрения платежа", error);
      });
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    refresh(limit.value, currentPage * limit.value, activeFilter, typePayments, sort, search);
  }, [currentPage, limit, activeFilter, typePayments, sort, debouncedSearch]);

  return (
    <div className="template_page analytics_page">
      <div className="template_page_title">
        <h1>Платежи</h1>
        <div className="users_search">
          <SearchIcon />
          <input
            type="text"
            value={search}
            placeholder="Поиск"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="cases_top_togglers">
        <button
          className={
            activeFilter == ""
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterItems("")}
        >
          <p>Все платежи</p>
        </button>
        <button
          className={
            activeFilter == "create"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterItems("create")}
        >
          <p>Создан</p>
        </button>
        <button
          className={
            activeFilter == "expired"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterItems("expired")}
        >
          <p>Отменен</p>
        </button>
        <button
          className={
            activeFilter == "success"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterItems("success")}
        >
          <p>Оплачен</p>
        </button>
        <button
          className={
            activeFilter == "manually approved"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterItems("manually approved")}
        >
          <p>Одобрен вручную</p>
        </button>
      </div>
      <div className="cases_top_togglers mt-3">
        <button 
          className={
            typePayments == ""
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterTypePayments("")}
        >
          <p>Все платежные системы</p>
        </button>
        <button
          className={
            typePayments == "lava"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterTypePayments("lava")}
        >
          <p>Платежная система ЛАВА(LAVA)</p>
        </button>
        <button
          className={
            typePayments == "yookassa"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterTypePayments("yookassa")}
        >
          <p>Платежная система ЮКасса(Yookassa)</p>
        </button>
        <button
          className={
            typePayments == "freekassa"
              ? "main_btn top_active_filter"
              : "main_btn"
          }
          onClick={() => filterTypePayments("freekassa")}
        >
          <p>Платежная система ФРИКАССА(Freekassa)</p>
        </button>
      </div>
      {payments && payments.length ? (
        <div className="payments_table">
          <table className="users_table">
            <thead>
              <tr>
                <th className="tal">
                  <button
                    className={
                      sort == "order_id" || sort == "-order_id" 
                        ? "td_btn active_td_btn"
                        : "td_btn"
                    }
                    onClick={() => {
                      if (sort !== 'order_id') {
                        setSort('order_id')
                      } 
                      if (sort === 'order_id') {
                        setSort('-order_id')
                      }
                    }}
                  >
                    <p>ID платежа</p>
                    {sort === 'order_id' ? <p>&uarr;</p> : sort === '-order_id' ? <p>&darr;</p> : ''}
                  </button>
                </th>
                <th className="tal">
                  <button
                    className={
                      sort == "user" || sort == "-user" 
                        ? "td_btn active_td_btn"
                        : "td_btn"
                    }
                    onClick={() => {
                      if (sort !== 'user') {
                        setSort('user')
                      } 
                      if (sort === 'user') {
                        setSort('-user')
                      }
                    }}
                  >
                    <p>ID юзера</p>
                    {sort === 'user' ? <p>&uarr;</p> : sort === '-user' ? <p>&darr;</p> : ''}
                  </button>
                </th>
                <th className="tal">
                  <button
                    className={
                      sort == "email" || sort == "-email" 
                        ? "td_btn active_td_btn"
                        : "td_btn"
                    }
                    onClick={() => {
                      if (sort !== 'email') {
                        setSort('email')
                      } 
                      if (sort === 'email') {
                        setSort('-email')
                      }
                    }}
                  >
                    <p>Email</p>
                    {sort === 'email' ? <p>&uarr;</p> : sort === '-email' ? <p>&darr;</p> : ''}
                  </button>
                </th>
                <th className="tal">Сумма пополнения</th>
                <th className="tal">
                  <button
                    className={
                      sort == "type_payments" || sort == "-type_payments" 
                        ? "td_btn active_td_btn"
                        : "td_btn"
                    }
                    onClick={() => {
                      if (sort !== 'type_payments') {
                        setSort('type_payments')
                      } 
                      if (sort === 'type_payments') {
                        setSort('-type_payments')
                      }
                    }}
                  >
                    <p>Способ оплаты</p>
                    {sort === 'type_payments' ? <p>&uarr;</p> : sort === '-type_payments' ? <p>&darr;</p> : ''}
                  </button>
                </th>
                <th className="tac">
                  <button
                    className={
                      sort == "created_at" || sort == "-created_at" 
                        ? "td_btn active_td_btn"
                        : "td_btn"
                    }
                    onClick={() => {
                      if (sort !== 'created_at') {
                        setSort('created_at')
                      } 
                      if (sort === 'created_at') {
                        setSort('-created_at')
                      }
                    }}
                  >
                  <p>Дата платежа</p>
                  {sort === 'created_at' ? <p>&uarr;</p> : sort === '-created_at' ? <p>&darr;</p> : ''}
                  </button>
                </th>
                <th className="tac">
                  <button
                    className={
                      sort == "status" || sort == "-status" 
                        ? "td_btn active_td_btn"
                        : "td_btn"
                    }
                    onClick={() => {
                      if (sort !== 'status') {
                        setSort('status')
                      } 
                      if (sort === 'status') {
                        setSort('-status')
                      }
                    }}
                  >
                    <p>Статус системы</p>
                    {sort === 'status' ? <p>&uarr;</p> : sort === '-status' ? <p>&darr;</p> : ''}
                  </button>
                </th>
                <th className="tac">Действия</th>
                <td className="users_select">
                  <div className="select_all">
                    <div className="is_selected ml_55px">
                      {selected.length == payments.length ? (
                        <SelectedIcon onClick={toggleAllDataSelected} />
                      ) : (
                        <div
                          className="not_selected_item"
                          onClick={toggleAllDataSelected}
                        ></div>
                      )}
                    </div>{" "}
                    Выделить все
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {payments
                ? payments.map((payment) => (
                    <tr className="review_raw" key={payment.id}>
                      <td className="" >
                        <p>{payment.id}</p>
                      </td>
                      <td className="">
                        <div
                          className="user_review"
                          onClick={() => aboutUser(payment.user)}
                          title="посмотреть профиль"
                        >
                          <p>{payment.user}</p>
                        </div>
                      </td>
                      <td className="">
                        <p>{payment.email}</p>
                      </td>
                      <td className="">
                        <p>{payment.sum} р.</p>
                      </td>
                      <td className="">
                        <p>{payment.type_payments}</p>
                      </td>
                      <td className="tac">
                        {format(payment.created_at, "yyyy-MM-dd HH:mm:ss")}
                      </td>
                      <td className="tac">
                        <p>{getStatus(payment.status)}</p>
                      </td>
                      <td className="tac center">
                        {payment.status == "create" ? (
                          <button
                            class="main_btn main_btn_template_green"
                            onClick={() => approveOutput(payment.order_id)}
                          >
                            <p>Одобрить</p>
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <div className="cases_table_actions">
                          <div className="cases_table_actions_list">
                            <div
                              title="посмотреть"
                              className="cases_table_edit"
                              onClick={() => aboutPayment(payment.order_id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="is_selected ">
                            {selected.some(
                              (selected) => selected.id === payment.id
                            ) ? (
                              <SelectedIcon
                                onClick={() => toggleSelected(payment)}
                              />
                            ) : (
                              <div
                                className="not_selected_item"
                                onClick={() => toggleSelected(payment)}
                              ></div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          <div className="cases_paginations">
            <Pagination
              pageCount={Math.ceil(paymentsDataLength / limit.value)}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
          <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
        </div>
      ) : (
        <p className="empty_error">Пусто</p>
      )}
    </div>
  );
}

export default Payments;
