import React from "react";
import "./snackbar.css";

function Snacbar({text, snackbar}) {
  return (
    <>
      <div className="snackbar_wrapper">
        <p>{text}</p>
        <div className="snackbar_error">
          {snackbar && snackbar.error && Object.keys(snackbar.error).map(e => {
            return <p>{snackbar.error[e]}(${e})</p>
          })}
        </div>
      </div>
    </>
  );
}

export default Snacbar;
