import React from "react";
import "./nav-bar.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import { NavLink } from "react-router-dom";
// import { useSelector } from "react-redux";
import Snacbar from "../snackbar/snackbar";

function NavBar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <nav>
      <div className="nav_wrapper">
        <NavLink to="/">
          <div className="nav_logo">
            <Logo />
          </div>
        </NavLink>
        <div className="nav_profile">
        <button
          className="logout_btn"
          onClick={handleLogout}
        >
          Выйти из аккаунта
        </button>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
