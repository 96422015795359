import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";

import {format} from "date-fns";

import Pagination from "../../components/pagionation/pagination";
import { mainApi } from "../../components/utils/main-api";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as TopIcon } from "../../assets/icons/top.svg";
import { ReactComponent as SelectedIcon } from "../../assets/icons/selected-icon.svg";
import "./cases.css";

function Cases() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [cases, setCases] = useState();
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [activeFilter, setActiveFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const handleGetCases = (limit, offset = 0, activeFilter, sort, search) => {
    mainApi
      .getCase(limit, offset, activeFilter, sort, search)
      .then((res) => {
        setCases(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const editCase = (id) => {
    navigate(`/edit-case/${id}`);
  };

  const handleDeleteCase = (id) => {
    mainApi
      .deleteCase(id)
      .then((res) => {
        handleGetCases(limit.value, currentPage * limit.value, activeFilter, sort, search);
        snackbarActions("Кейс удалён!");
      })
      .catch((error) => {
        console.log("error", error);
        snackbarActions("Ошибка", error);
      });
    handleGetCases(limit.value, currentPage * limit.value, activeFilter, sort, search);
  };

  const deleteCases = () => {
    const bulkDestroy = selected.map((el) => el.case_id)
    mainApi
      .bulkDestroyCasesAction(bulkDestroy)
      .then((res) => {
        handleGetCases(limit.value, currentPage * limit.value, activeFilter, sort, search);
        snackbarActions(res.message);
        setSelected([]);
      })
      .catch((error) => {
        snackbarActions("Ошибка", error);
      });
  };

  const filterItems = (type) => {
    setActiveFilter(type);
    setCurrentPage(0);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.case_id === data.case_id
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.case_id !== data.case_id));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length == cases.length) {
      setSelected([]);
    } else {
      setSelected([...cases]);
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    mainApi
      .getCaseCategoryAction()
      .then((res) => {
        setCategories(res.results);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    handleGetCases(limit.value, currentPage * limit.value, activeFilter, sort, search);
  }, [currentPage, activeFilter, sort, limit, debouncedSearch]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="template_page employees_page">
        <div className="template_page_title">
          <h1>Кейсы</h1>
          <div className="top_cases_actions">
            <NavLink to="/create-case">
              <button className="main_btn add_case_btn main_btn_template">
                <p>Добавить кейс</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
            <NavLink to="/cases-category">
              <button className="main_btn add_categories_btn main_btn_template_orange">
                <p>Добавить категорию</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
            <NavLink to="/conditions">
              <button className="main_btn main_btn_template_red ">
                <p>Добавить условие</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
        <div className="template_page_content">
          <div className="cases_wrapper">
            <div className="cases_top_togglers">
              <button
                className={
                  activeFilter == ""
                    ? "main_btn top_active_filter"
                    : "main_btn"
                }
                onClick={() => filterItems("")}
              >
                <p>Все кейсы</p>
              </button>
              {categories && categories.length
                ? categories.map((categories) => (
                    <button
                      className={
                        activeFilter == categories.category_id
                          ? "main_btn top_active_filter"
                          : "main_btn"
                      }
                      key={categories.name}
                      onClick={() => filterItems(categories.category_id)}
                    >
                      <p>{categories.name}</p>
                    </button>
                  ))
                : ""}
            </div>
            <div className="cases_top_actions">
              {selected.length !==0 && (
                <button
                  className="main_btn main_btn_template_red"
                  onClick={deleteCases}
                >
                  <p>Массовое удаление</p>
                </button>
              )}
              <div className="users_search">
                <SearchIcon />
                <input
                  type="text"
                  value={search}
                  placeholder="Поиск"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="user_line"></div>

            {cases && cases.length ? (
              <>
                <table className="cases_table">
                  <thead>
                    <tr>
                      <td>
                        <button
                          className={
                            sort == "case_id" || sort == "-case_id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'case_id') {
                              setSort('case_id')
                            } 
                            if (sort === 'case_id') {
                              setSort('-case_id')
                            }
                          }}
                        >
                          <p>ID кейса</p>
                          {sort === 'case_id' ? <p>&uarr;</p> : sort === '-case_id' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "name" || sort == "-name" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'name') {
                              setSort('name')
                            } 
                            if (sort === 'name') {
                              setSort('-name')
                            }
                          }}
                        >
                          <p>Название</p>
                          {sort === 'name' ? <p>&uarr;</p> : sort === '-name' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "category" || sort == "-category" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'category') {
                              setSort('category')
                            } 
                            if (sort === 'category') {
                              setSort('-category')
                            }
                          }}
                        >
                          <p>Категория</p>
                          {sort === 'category' ? <p>&uarr;</p> : sort === '-category' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">
                        <button
                          className={
                            sort == "price" || sort == "-price" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'price') {
                              setSort('price')
                            } 
                            if (sort === 'price') {
                              setSort('-price')
                            }
                          }}
                        >
                          <p>Цена (руб)</p>
                          {sort === 'price' ? <p>&uarr;</p> : sort === '-price' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">
                        <button
                          className={
                            sort == "case_free" || sort == "-case_free" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'case_free') {
                              setSort('case_free')
                            } 
                            if (sort === 'case_free') {
                              setSort('-case_free')
                            }
                          }}
                        >
                          <p>Бесплатный</p>
                          {sort === 'case_free' ? <p>&uarr;</p> : sort === '-case_free' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">
                        <button
                          className={
                            sort == "created_at" || sort == "-created_at" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'created_at') {
                              setSort('created_at')
                            } 
                            if (sort === 'created_at') {
                              setSort('-created_at')
                            }
                          }}
                        >
                          <p>Дата создания</p>
                          {sort === 'created_at' ? <p>&uarr;</p> : sort === '-created_at' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td className="tac">Условие</td>
                      <td>
                        <div className="select_all">
                          <div className="is_selected ">
                            {selected.length == cases.length ? (
                              <SelectedIcon onClick={toggleAllDataSelected} />
                            ) : (
                              <div
                                className="not_selected_item"
                                onClick={toggleAllDataSelected}
                              ></div>
                            )}
                          </div>{" "}
                          Выделить все
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {cases && cases.length
                      ? cases.map((cases) => (
                          <tr key={cases.case_id}>
                            <td>{cases.case_id || "-"}</td>
                            <td>{cases.name || "-"}</td>
                            <td>
                              {cases.category && cases.category.name
                                ? cases.category.name
                                : "-"}
                            </td>
                            <td className="tac">{cases.price || 0} ₽</td>

                            <td className="tac">
                              {cases.case_free ? "Да" : "Нет"}
                            </td>

                            <td className="tac">
                              {cases && format(cases.created_at, "yyyy-MM-dd HH:mm:ss")}
                            </td>
                            <td className="tac">
                              {cases.conditions && cases.conditions.map((el) =>(
                                <p key={el.condition_id}>{el.name}</p>))}
                            </td>
                            <td>
                              <div className="cases_table_actions">
                                <div className="cases_table_actions_list">
                                  <div
                                    title="редактировать"
                                    className="cases_table_edit"
                                    onClick={() => editCase(cases.case_id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M2.99902 17.2512V21.0012H6.74902L17.809 9.94125L14.059 6.19125L2.99902 17.2512ZM20.709 7.04125C21.099 6.65125 21.099 6.02125 20.709 5.63125L18.369 3.29125C17.979 2.90125 17.349 2.90125 16.959 3.29125L15.129 5.12125L18.879 8.87125L20.709 7.04125Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>

                                  <div
                                    title="удалить"
                                    className="cases_table_delete"
                                    onClick={() =>
                                      handleDeleteCase(cases.case_id)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div className="is_selected ">
                                  {selected.some(
                                    (selected) =>
                                      selected.case_id === cases.case_id
                                  ) ? (
                                    <SelectedIcon
                                      onClick={() => toggleSelected(cases)}
                                    />
                                  ) : (
                                    <div
                                      className="not_selected_item"
                                      onClick={() => toggleSelected(cases)}
                                    ></div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
                <div className="cases_paginations">
                  <Pagination
                    pageCount={Math.ceil(dataLength / limit.value)}
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
                <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
              </>
            ) : (
              <p className="empty_error">Кейсы отсутствуют</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Cases;
