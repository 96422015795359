import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";

import { mainApi } from "../../components/utils/main-api";
import {snackbarAction, snackbarClearAction} from "../../redux/snackbar-reducer";

import "./settings.css";

function Settings() {
  const dispatch = useDispatch();

  const [settings, setSettings] = useState();
  const [openedCasesBuff, setOpenedCasesBuff] = useState();
  const [usersBuff, setUsersBuff] = useState();
  const [minRefOutput, setMinRefOutput] = useState();
  const [onlineBuff, setOnlineBuff] = useState();
  const [purchaseBuff, setPurchaseBuff] = useState();
  const [outputCrystalBuff, setOutputCrystalBuff] = useState();
  const [defaultMarkUpCase, setDefaultMarkUpCase] = useState();
  const [baseUpgradePercent, setBaseUpgradePercent] = useState();
  const [minimalPriceUpgrade, setMinimalPriceUpgrade] = useState();
  const [baseUpperRatio, setBaseUpperRatio] = useState();
  const [notifyDomain, setNotifyDomain] = useState();
  const [freeKassaSuccessUrl, setFreeKassaSuccessUrl] = useState();
  const [freeKassaFailureUrl, setFreeKassaFailureUrl] = useState();
  const [emailRedirect, setEmailRedirect] = useState();
  const [emailVerify, setEmailVerify] = useState();
  const [telegramToken, setTelegramToken] = useState();

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 5000);
  };

  const saveSettings = () => {
     mainApi
       .updateSettingsAction(
         {
          opened_cases_buff: openedCasesBuff,
          users_buff: usersBuff,
          min_ref_output: minRefOutput,
          online_buff: onlineBuff,
          purchase_buff: purchaseBuff,
          output_crystal_buff: outputCrystalBuff,
          default_mark_up_case: defaultMarkUpCase,
          base_upgrade_percent: baseUpgradePercent,
          minimal_price_upgrade: minimalPriceUpgrade,
          base_upper_ratio: baseUpperRatio,
          notify_domain: notifyDomain,
          free_kassa_success_url: freeKassaSuccessUrl,
          free_kassa_failure_url: freeKassaFailureUrl,
          email_verify_url_redirect: emailRedirect,
          email_verify_url: emailVerify,
          telegram_verify_bot_token: telegramToken,
         }
       )
       .then((res) => {
         snackbarActions('Настройки обновлены')
       })
       .catch((error) => {
         console.log("error", error);
         snackbarActions('Ошибка обновления', error)

       });
  };

  const refresh = () => {
    mainApi
      .getSetting()
      .then((res) => {
        setSettings(res);
        setOpenedCasesBuff(res.opened_cases_buff);
        setUsersBuff(res.users_buff);
        setMinRefOutput(res.min_ref_output);
        setOnlineBuff(res.online_buff);
        setPurchaseBuff(res.purchase_buff);
        setOutputCrystalBuff(res.output_crystal_buff);
        setDefaultMarkUpCase(res.default_mark_up_case);
        setBaseUpgradePercent(res.base_upgrade_percent);
        setMinimalPriceUpgrade(res.minimal_price_upgrade);
        setBaseUpperRatio(res.base_upper_ratio);
        setNotifyDomain(res.notify_domain);
        setFreeKassaSuccessUrl(res.free_kassa_success_url);
        setFreeKassaFailureUrl(res.free_kassa_failure_url);
        setEmailRedirect(res.email_verify_url_redirect);
        setEmailVerify(res.email_verify_url);
        setTelegramToken(res.telegram_verify_bot_token);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className="template_page">
      <div className="template_page_title">
        <h1>Настройки</h1>
      </div>
      <div className="template_page_content">
        {settings ? (
          <div className="settings_page_wrapper">
            <div className="settings_information_wrapper">
              <div className="personal_information_block">
                <p>Баф для открытых кейсов</p>
                <input
                  type="text"
                  placeholder="0"
                  value={openedCasesBuff}
                  onChange={(e) => setOpenedCasesBuff(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Баф всего пользователей</p>
                <input
                  type="text"
                  placeholder="0"
                  value={usersBuff}
                  onChange={(e) => setUsersBuff(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Баф онлайна</p>
                <input
                  type="text"
                  placeholder="0"
                  value={onlineBuff}
                  onChange={(e) => setOnlineBuff(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>	Баф покупок</p>
                <input
                  type="text"
                  placeholder="0"
                  value={purchaseBuff}
                  onChange={(e) => setPurchaseBuff(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Баф выводов</p>
                <input
                  type="text"
                  placeholder="0"
                  value={outputCrystalBuff}
                  onChange={(e) => setOutputCrystalBuff(e.target.value)}
                />
              </div>
            </div>
            <div className="settings_line"></div>
            <div className="settings_information_wrapper">
              <div className="personal_information_block">
                <p>Минимальная сумма вывода с реферальной программы</p>
                <input
                  type="text"
                  placeholder="0"
                  value={minRefOutput}
                  onChange={(e) => setMinRefOutput(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Дефолтная наценка на кейсы</p>
                <input
                  type="text"
                  placeholder="0"
                  value={defaultMarkUpCase}
                  onChange={(e) => setDefaultMarkUpCase(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Базовая ставка расчёта (Диапозон 0.5-1)</p>
                <input
                  type="text"
                  placeholder="0"
                  value={baseUpgradePercent}
                  onChange={(e) => setBaseUpgradePercent(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Минимальная ставка при апгрейде</p>
                <input
                  type="text"
                  placeholder="0"
                  value={minimalPriceUpgrade}
                  onChange={(e) => setMinimalPriceUpgrade(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Минимальный коэффициент возвышения</p>
                <input
                  type="text"
                  placeholder="0"
                  value={baseUpperRatio}
                  onChange={(e) => setBaseUpperRatio(e.target.value)}
                />
              </div>
            </div>
            <div className="settings_line"></div>
            <div className="settings_information_wrapper">
              <div className="personal_information_block">
                <p>Домен для уведомлений</p>
                <input
                  type="text"
                  placeholder="0"
                  value={notifyDomain}
                  onChange={(e) => setNotifyDomain(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Домен для переадресации после оплаты в случае успеха</p>
                <input
                  type="text"
                  placeholder="0"
                  value={freeKassaSuccessUrl}
                  onChange={(e) => setFreeKassaSuccessUrl(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Домен для переадресации после оплаты в случае неудачи</p>
                <input
                  type="text"
                  placeholder="0"
                  value={freeKassaFailureUrl}
                  onChange={(e) => setFreeKassaFailureUrl(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Домен для переадресации после подтверждения email</p>
                <input
                  type="text"
                  placeholder="0"
                  value={emailRedirect}
                  onChange={(e) => setEmailRedirect(e.target.value)}
                />
              </div>
              <div className="personal_information_block">
                <p>Домен для подтверждения</p>
                <input
                  type="text"
                  placeholder="0"
                  value={emailVerify}
                  onChange={(e) => setEmailVerify(e.target.value)}
                />
              </div>
            </div>
            <div className="settings_line"></div>
            <div className="personal_information_block">
              <p>Токен телеграмм бота для подтверждения подписок</p>
              <input
                type="text"
                placeholder="0"
                value={telegramToken}
                onChange={(e) => setTelegramToken(e.target.value)}
              />
            </div>
            <div className="admin_actions case_actions">
                <button
                  className="create_admin_btn main_btn_template_green"
                  onClick={saveSettings}
                >
                  Сохранить
                </button>
                <button
                  className="undo_create main_btn_template_border"
                  onClick={refresh}
                >
                  Отменить
                </button>
              </div>
          </div>
        ): (
          ""
        )}
      </div>
    </div>
  );
}

export default Settings;
