import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";

import {format} from "date-fns";

import Pagination from "../../components/pagionation/pagination";
import { mainApi } from "../../components/utils/main-api";
import {snackbarAction, snackbarClearAction} from "../../redux/snackbar-reducer";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import { ReactComponent as SelectedIcon } from "../../assets/icons/selected-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";

function RefLinks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [refLinks, setRefLinks] = useState();
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const handleGetRefLinks = (limit, offset = 0, sort, search) => {
    mainApi
      .getRefLinks(limit, offset, sort, search)
      .then((res) => {
        setRefLinks(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const editRefLink = (code_data) => {
    navigate(`/edit-ref-links/${code_data}`);
  };

  const handleDeleteRefLink = (id) => {
    mainApi
      .deleteRefLinks(id)
      .then((res) => {
      })
      .catch((error) => {
      });
    snackbarActions("Промокод удален!");
    setTimeout(() => {
      handleGetRefLinks(limit.value, currentPage * limit.value, sort, search);
    }, 1200);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.code_data === data.code_data
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.code_data !== data.code_data));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length == refLinks.length) {
      setSelected([]);
    } else {
      setSelected([...refLinks]);
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    handleGetRefLinks(limit.value, currentPage * limit.value, sort, search);
  }, [currentPage, sort, limit, debouncedSearch]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="template_page employees_page">
        <div className="template_page_title">
          <h1>Реф. Система</h1>
        </div>
        <div className="users_search">
          <SearchIcon />
          <input
            type="text"
            value={search}
            placeholder="Поиск"
            onChange={handleInputChange}
          />
        </div>
        <div className="template_page_content">
          <div className="cases_wrapper">
            {refLinks && refLinks.length ? (
              <>
                <table className="cases_table">
                  <thead>
                    <tr>
                      <td>
                        <button
                          className={
                            sort == "code_data" || sort == "-code_data" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'code_data') {
                              setSort('code_data')
                            } 
                            if (sort === 'code_data') {
                              setSort('-code_data')
                            }
                          }}
                        >
                          <p>ID</p>
                          {sort === 'code_data' ? <p>&uarr;</p> : sort === '-code_data' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "created_at" || sort == "-created_at" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'created_at') {
                              setSort('created_at')
                            } 
                            if (sort === 'created_at') {
                              setSort('-created_at')
                            }
                          }}
                        >
                          <p>Дата создания</p>
                          {sort === 'created_at' ? <p>&uarr;</p> : sort === '-created_at' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>Активный</td>
                      <td>Имя пользователя</td>
                      <td>
                        <button
                          className={
                            sort == "partner_percent" || sort == "-partner_percent" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'partner_percent') {
                              setSort('partner_percent')
                            } 
                            if (sort === 'partner_percent') {
                              setSort('-partner_percent')
                            }
                          }}
                        >
                          <p>Процент от рефералов</p>
                          {sort === 'partner_percent' ? <p>&uarr;</p> : sort === '-partner_percent' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "partner_income" || sort == "-partner_income" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'partner_income') {
                              setSort('partner_income')
                            } 
                            if (sort === 'partner_income') {
                              setSort('-partner_income')
                            }
                          }}
                        >
                          <p>Поступления с рефералов</p>
                          {sort === 'partner_income' ? <p>&uarr;</p> : sort === '-partner_income' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "all_debit" || sort == "-all_debit" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'all_debit') {
                              setSort('all_debit')
                            } 
                            if (sort === 'all_debit') {
                              setSort('-all_debit')
                            }
                          }}
                        >
                          <p>Депозитов за все время</p>
                          {sort === 'all_debit' ? <p>&uarr;</p> : sort === '-all_debit' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "all_output" || sort == "-all_output" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'all_output') {
                              setSort('all_output')
                            } 
                            if (sort === 'all_output') {
                              setSort('-all_output')
                            }
                          }}
                        >
                          <p>Вывел</p>
                          {sort === 'all_output' ? <p>&uarr;</p> : sort === '-all_output' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "date_joined" || sort == "-date_joined" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'date_joined') {
                              setSort('date_joined')
                            } 
                            if (sort === 'date_joined') {
                              setSort('-date_joined')
                            }
                          }}
                        >
                          <p>Дата регистрации пользователя</p>
                          {sort === 'date_joined' ? <p>&uarr;</p> : sort === '-date_joined' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <div className="select_all">
                          <div className="is_selected ">
                            {selected.length == refLinks.length ? (
                              <SelectedIcon onClick={toggleAllDataSelected} />
                            ) : (
                              <div
                                className="not_selected_item"
                                onClick={toggleAllDataSelected}
                              ></div>
                            )}
                          </div>{" "}
                          Выделить все
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {refLinks && refLinks.length
                      ? refLinks.map((refLink) => (
                        <tr key={refLink.code_data}>
                          <td>{refLink.code_data || "-"}</td>
                          <td>
                            {refLink && format(refLink.created_at, "yyyy-MM-dd HH:mm:ss")}
                          </td>
                          <td>
                            {refLink.active ? "Да" : "Нет"}
                          </td>
                          <td>{`${refLink.from_user?.username || "-"} : ${refLink.from_user?.first_name || "-"} ${refLink.from_user?.last_name || "-"}`}</td>
                          <td>{refLink.from_user?.partner_percent}</td>
                          <td>{refLink.from_user?.partner_income}</td>
                          <td>{refLink.from_user?.all_debit} ₽</td>
                          <td>{refLink.from_user?.all_output} ₽</td>
                          <td>
                            {refLink && format(refLink.from_user?.date_joined, "yyyy-MM-dd HH:mm:ss")}
                          </td>
                          <td>
                            <div className="cases_table_actions">
                              <div className="cases_table_actions_list">
                                <div
                                  title="редактировать"
                                  className="cases_table_edit"
                                  onClick={() => editRefLink(refLink.code_data)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.99902 17.2512V21.0012H6.74902L17.809 9.94125L14.059 6.19125L2.99902 17.2512ZM20.709 7.04125C21.099 6.65125 21.099 6.02125 20.709 5.63125L18.369 3.29125C17.979 2.90125 17.349 2.90125 16.959 3.29125L15.129 5.12125L18.879 8.87125L20.709 7.04125Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                                <div
                                  title="удалить"
                                  className="cases_table_delete"
                                  onClick={() =>
                                    handleDeleteRefLink(refLink.code_data)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="is_selected">
                                {selected.some(
                                  (selected) =>
                                    selected.code_data === refLink.code_data
                                ) ? (
                                  <SelectedIcon
                                    onClick={() => toggleSelected(refLink)}
                                  />
                                ) : (
                                  <div
                                    className="not_selected_item"
                                    onClick={() => toggleSelected(refLink)}
                                  ></div>
                                )}
                                </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : ""}
                  </tbody>
                </table>
                <div className="cases_paginations">
                  <Pagination
                    pageCount={Math.ceil(dataLength / limit.value)}
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
                <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
              </>
            ) : (
              <p className="empty_error">Реф. системы отсутствуют</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RefLinks;
