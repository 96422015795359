import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "react-select";

import { mainApi } from "../../components/utils/main-api";
import {snackbarAction, snackbarClearAction} from "../../redux/snackbar-reducer";

import { ReactComponent as ArrowBackIcon } from "../../assets/icons/arrow-back.svg";
import "react-calendar/dist/Calendar.css";

function CreateRefOutput() {
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [active, setActive] = useState(true);
  const [sum, setSum] = useState();
  const [type, setType] = useState();
  const [comment, setComment] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [phone, setPhone] = useState();
  const [status, setStatus] = useState();
  const [cryptoNumber, setCryptoNumber] = useState();

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const createRefOutput = () => {
    mainApi
      .createRefOutputsAction({
        ref_output_id: id,
        active,
        sum,
        comment,
        phone,
        card_number: cardNumber,
        crypto_number: cryptoNumber,
        type: type?.value,
        status: status?.value,
      })
      .then((res) => {
        snackbarActions("Выплата создана");
      })
      .catch((error) => {
        console.log("error", error);
        snackbarActions("Ошибка создания выплаты", error);
      });
  };

  return (
    <div className="template_page create_item_page">
      <div className="template_page_title">
        <h1>Создать выплату партнеру</h1>
      </div>
      <div className="user_line"></div>
      <NavLink to="/ref_outputs">
        <div className="back_btn">
          <ArrowBackIcon /> <p>Назад</p>
        </div>
      </NavLink>
      <div className="create_item_wrapper create_competition_wrapper">
        <h2 className="create_competition_wrapper_block_title">
          Информация
        </h2>
        <div className="case_input_temp">
          <p>ID</p>
          <input
            type="text"
            placeholder="Введите ID"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </div>
        <div className="conditions_select">
          <p>Тип платежа</p>
          <Select
            options={[{
              value: "card",
              label: "Вывод на банковскую карту",
            },
            {
              value: "cryptocurrency",
              label: "Вывод на криптокошелек",
              },
            {
              value: "sbp",
              label: "Вывод по СБП",
            }]}
            className="conditions_select_box"
            onChange={setType}
            value={type}
            placeholder={<div>Выбор</div>}
          />
        </div>
        <div className="conditions_select">
          <p>Статус платежа</p>
          <Select
            options={[{
              value: "created",
              label: "Созданный",
            },
            {
              value: "completed",
              label: "Завершенный",
              },
            {
              value: "canceled",
              label: "Отмененный",
            }]}
            className="conditions_select_box"
            onChange={setStatus}
            value={status}
            placeholder={<div>Выбор</div>}
          />
        </div>
        <div className="case_input_temp">
          <p>Сумма вывода</p>
          <input
            type="text"
            placeholder="0"
            value={sum}
            onChange={(e) => setSum(e.target.value)}
          />
        </div>
        <div className="case_input_temp">
          <p>Номер телефона</p>
          <input
            type="text"
            placeholder="-"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="case_input_temp">
          <p>Номер карты</p>
          <input
            type="text"
            placeholder="-"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
        </div>
        <div className="case_input_temp">
          <p>Номер криптокошелька</p>
          <input
            type="text"
            placeholder="-"
            value={cryptoNumber}
            onChange={(e) => setCryptoNumber(e.target.value)}
          />
        </div>
        <div className="case_input_temp">
          <p>Комментарий</p>
          <input
            type="text"
            placeholder="-"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="user_checkboxes contest_conds">
          <div className="user_checkbox">
            <p>Активный:</p>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </div>
        </div>
        <div className="admin_actions case_actions">
          <button
            className="create_admin_btn main_btn_template_green"
            onClick={createRefOutput}
          >
            Создать
          </button>
          <NavLink to="/ref_outputs">
            <button className="undo_create main_btn_template_border">
              Отменить
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default CreateRefOutput;
