import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import PaginationSecondary from "../../components/pagionation-secondary/pagination-secondary";
import { mainApi } from "../../components/utils/main-api";
import { format } from "date-fns";

import { ReactComponent as ArrowBackIcon } from "../../assets/icons/arrow-back.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import avatar from "../../assets/images/avatar.png";
import "./user-page.css";

function UserPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const params = useParams();
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    link_tg: "",
    email: "",
    verified: false,
    balance: 0,
    winrate: 0,
    individual_percent: 0,
    partner_percent: 0,
    partner_income: 0,
    total_withdrawal: 0,
    available_withdrawal: 0,
    all_debit: 0,
    all_output: 0,
    image: avatar,
  });

  const [userGames, setUserGames] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [userPayments, setUserPayments] = useState([]);
  const [sort, setSort] = useState('id');

  // get user
  useEffect(() => {
    mainApi
      .getUserAction(params.user)
      .then((res) => {
        setUser(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [params.user]);

  // get user game
  const [dataLengthG, setDataLengthG] = useState();
  const [currentPageG, setCurrentPageG] = useState(0);
  const [activeFilterGames, setActiveFilterGames] = useState("");

  const filterItemsGames = (type) => {
    setActiveFilterGames(type);
    setCurrentPageG(0);
  };

  const getUserGames = (offset = 0, sort, status) => {
    mainApi
      .getUserGamesAction(params.user, offset, sort, status)
      .then((res) => {
        setUserGames(res.results);
        setDataLengthG(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getUserGames(currentPageG * 10, sort, activeFilterGames);
  }, [currentPageG, sort, activeFilterGames]);

  // get user items
  const [dataLengthI, setDataLengthI] = useState();
  const [currentPageI, setCurrentPageI] = useState(0);
  const [sortUserItems, setSortUserItems] = useState('id');
  const [activeFilterUserItems, setActiveFilterUserItems] = useState("");

  useEffect(() => {
    getUserItems(currentPageI * 10, sortUserItems, activeFilterUserItems);
  }, [currentPageI, sortUserItems, activeFilterUserItems]);

  const filterUserItems = (type) => {
    setActiveFilterUserItems(type);
    setCurrentPageI(0);
  };

  const getUserItems = (offset = 0, sort, status) => {
    mainApi
      .getUserItemsAction(params.user, offset, sort, status)
      .then((res) => {
        setUserItems(res.results);
        setDataLengthI(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // get user payments
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [sortPayments, setSortPayments] = useState('id');
  const [activeFilterPayments, setActiveFilterPayments] = useState("");

  useEffect(() => {
    getUserPayments(currentPage * 10, sortPayments, activeFilterPayments);
  }, [currentPage, sortPayments, activeFilterPayments]);

  const filterItemsPayments = (type) => {
    setActiveFilterPayments(type);
    setCurrentPage(0);
  };

  const getUserPayments = (offset = 0, sort, status) => {
    mainApi
      .getUserPaymentsAction(params.user, offset, sort, status)
      .then((res) => {
        setUserPayments(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const aboutUser = () => {
    navigate(`/edit-user/${params.user}`);
  };

  // get user payments
  const [userItemsHistory, setUserItemsHistory] = useState([]);
  const [activeFilterItemsHistory, setActiveFilterItemsHistory] = useState("");
  const [sortItemsHistory, setSortItemsHistory] = useState('id');

  const filterItemsHistory = (type) => {
    setActiveFilterItemsHistory(type);
  };

  useEffect(() => {
    getItemsHistory(sortItemsHistory, activeFilterItemsHistory);
  }, [sortItemsHistory, activeFilterItemsHistory]);

  const getItemsHistory = (sort, status) => {
    mainApi
      .getItemsHistoryAction(params.user, sort, status)
      .then((res) => {
        setUserItemsHistory(res.results);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="template_page user_page">
      <div className="template_page_title">
        <h1>Профиль пользователя: {user.username !== undefined ? user.username: ""}</h1>
      </div>
      <div className="user_line"></div>
      <NavLink to={`/users`}>
        <div className="back_btn">
          <ArrowBackIcon /> <p>Назад</p>
        </div>
      </NavLink>
      <div className="template_page_content">
        {user ? (
          <div className="user_page_wrapper">
            <div className="user_main_data">
              <p>Фото изображение человека</p>
              <div className="user_top_actions_btns">
                <div className="user_image">
                  <img src={user.image || avatar} alt="" />
                </div>
                <div className="user_action_btns">
                  {/* <button className="main_btn main_btn_template_red">
                    {" "}
                    Заблокировать{" "}
                  </button> */}
                  <button className="main_btn" onClick={aboutUser}>
                    Редактировать
                  </button>
                </div>
              </div>
            </div>
            <div className="user_line"></div>
            <div className="personal_informations">
              <p className="user_block_title">Персональная информация</p>
              <div className="personal_informations_list">
                <div className="personal_information_wrapper">
                  <div className="personal_information_block">
                    <p>Имя пользователя</p>
                    <input
                      type="text"
                      placeholder="-"
                      value={`${user.first_name || ""} ${user.last_name || ""}`}
                      // value={user.first_name !== undefined ? user.first_name : ""}
                      readOnly
                    />
                  </div>
                  <div className="personal_information_block">
                    <p>Аккаунт телеграм</p>
                    <input
                      type="text"
                      placeholder="-"
                      // value={user.link_tg !== undefined ? user.link_tg : ""}
                      value={user.link_tg || ""}
                      readOnly
                    />
                    <svg
                      className="copy_icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_379_7747)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.02286 0.75C1.31988 0.75 0.75 1.31988 0.75 2.02286V11.2629C0.75 11.9658 1.31988 12.5357 2.02286 12.5357H5.46429V15.9771C5.46429 16.6801 6.03416 17.25 6.73714 17.25H15.9771C16.6801 17.25 17.25 16.6801 17.25 15.9771V6.73714C17.25 6.03416 16.6801 5.46429 15.9771 5.46429H12.5357V2.02286C12.5357 1.31988 11.9658 0.75 11.2629 0.75H2.02286ZM11.1214 5.46429V2.16429H2.16429V11.1214H5.46429V6.73714C5.46429 6.03416 6.03416 5.46429 6.73714 5.46429H11.1214ZM6.87857 15.8357H15.8357V6.87857H6.87857V15.8357Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_379_7747">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="personal_information_block">
                    <p>Почта</p>
                    <input
                      type="text"
                      placeholder="-"
                      // value={user.email !== undefined ? user.email : ""}
                      value={user.email || ""}
                      readOnly
                    />
                  </div>
                  <div className="personal_information_block">
                    <p>Аккаунт вконтакте</p>
                    <input
                      type="text"
                      placeholder="-"
                      value={user.link_vk !== undefined ? user.link_vk : ""}
                      readOnly
                    />
                    <svg
                      className="copy_icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_379_7747)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.02286 0.75C1.31988 0.75 0.75 1.31988 0.75 2.02286V11.2629C0.75 11.9658 1.31988 12.5357 2.02286 12.5357H5.46429V15.9771C5.46429 16.6801 6.03416 17.25 6.73714 17.25H15.9771C16.6801 17.25 17.25 16.6801 17.25 15.9771V6.73714C17.25 6.03416 16.6801 5.46429 15.9771 5.46429H12.5357V2.02286C12.5357 1.31988 11.9658 0.75 11.2629 0.75H2.02286ZM11.1214 5.46429V2.16429H2.16429V11.1214H5.46429V6.73714C5.46429 6.03416 6.03416 5.46429 6.73714 5.46429H11.1214ZM6.87857 15.8357H15.8357V6.87857H6.87857V15.8357Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_379_7747">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  {/*<div className="personal_information_block">
                    <p>Способы входа</p>
                    <div className="user_socials">
                      <Google />
                      <Vk />
                      <TG />
                      <Mailru />
                      <Yandex />
                    </div>
                  </div>*/}
                  {user.date_joined ? (
                    <div className="personal_information_block">
                      <p>Дата регистрации пользователя</p>
                      <input
                        type="text"
                        placeholder="-"
                        value={format(user.date_joined, "yyyy-MM-dd HH:mm:ss")}
                        readOnly
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="user_line"></div>

            <div className="user_activity">
              <p className="user_block_title">Игровая активность</p>
              <div className="user_activities">
                <div className="personal_information_block">
                  <p>Баланс юзера</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.balance}
                    readOnly
                  />
                </div>
                <div className="personal_information_block winrate_edit">
                  <p>Винрейт</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.winrate}
                    readOnly
                  />
                </div>
                <div className="personal_information_block winrate_edit">
                  <p>Процент накрутки</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.individual_percent}
                    readOnly
                  />
                </div>
              </div>
              <p className="user_block_title mt_40px">Реферальная система</p>{" "}
              <div className="user_activities">
                <div className="personal_information_block">
                  <p>Процент от рефералов</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.partner_percent}
                    readOnly
                  />
                </div>
                <div className="personal_information_block">
                  <p>Поступления с рефералов</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.partner_income}
                    readOnly
                  />
                </div>
                <div className="personal_information_block">
                  <p>Вывел с реферального баланса</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.total_withdrawal}
                    readOnly
                  />
                </div>
                <div className="personal_information_block">
                  <p>Доступно для вывода</p>
                  <input
                    type="text"
                    placeholder="0"
                    value={user.available_withdrawal}
                    readOnly
                  />
                </div>
              </div>
              <div className="user_checkboxes">
                <div className="user_checkbox">
                  <p>Верифицирован:</p>
                  <input type="checkbox" checked={user.verified || false} readOnly />
                </div>
                <div className="user_checkbox">
                  <p>Демо:</p>
                  <input type="checkbox" checked={user.verified || false} readOnly />
                </div>
              </div>
            </div>

            {/* game */}
            <div className="user_operations">
              <div className="user_operations_title">
                <p className="user_block_title">История открытий</p>
                <div className="cases_top_togglers">
                  <button
                    className={
                      activeFilterGames == ""
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsGames("")}
                  >
                    <p>Все статусы</p>
                  </button>
                  <button
                    className={
                      activeFilterGames == "active"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsGames("active")}
                  >
                    <p>На аккаунте</p>
                  </button>
                  <button
                    className={
                      activeFilterGames == "inactive"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsGames("inactive")}
                  >
                    <p>Продан</p>
                  </button>
                  <button
                    className={
                      activeFilterGames == "withdrawn"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsGames("withdrawn")}
                  >
                    <p>Выведен</p>
                  </button>
                </div>
                <div className="users_search">
                  <SearchIcon />
                  <input type="text" placeholder="Поиск" />
                </div>
              </div>
              <div className="user_operations_table">
                <table className="user_table">
                  <thead>
                    <tr>
                      <th>
                        <button
                          className={
                            sort == "id" || sort == "-id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'id') {
                              setSort('id')
                            } 
                            if (sort === 'id') {
                              setSort('-id')
                            }
                          }}
                        >
                          <p>ID</p>
                          {sort === 'id' ? <p>&uarr;</p> : sort === '-id' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sort == "case_name" || sort == "-case_name" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'case_name') {
                              setSort('case_name')
                            } 
                            if (sort === 'case_name') {
                              setSort('-case_name')
                            }
                          }}
                        >
                          <p>Название кейса</p>
                          {sort === 'case_name' ? <p>&uarr;</p> : sort === '-case_name' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>Полученный предмет</th>
                      <th>Кол-во</th>
                      <th>
                        <button
                          className={
                            sort == "case_price" || sort == "-case_price" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'case_price') {
                              setSort('case_price')
                            } 
                            if (sort === 'case_price') {
                              setSort('-case_price')
                            }
                          }}
                        >
                          <p>Цена кейса</p>
                          {sort === 'case_price' ? <p>&uarr;</p> : sort === '-case_price' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sort == "item_price_sold" || sort == "-item_price_sold" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'item_price_sold') {
                              setSort('item_price_sold')
                            } 
                            if (sort === 'item_price_sold') {
                              setSort('-item_price_sold')
                            }
                          }}
                        >
                          <p>Цена предмета</p>
                          {sort === 'item_price_sold' ? <p>&uarr;</p> : sort === '-item_price_sold' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>Закупочная цена</th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userGames && userGames.length ? (
                      userGames.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id || "-"}</td>
                          <td>{item.case.name}</td>
                          <td>{item.item.name}</td>
                          <td>{item.quantity || "1"}</td>
                          <td>{item.case.price || "-"} р.</td>
                          <td>{item.item.price || "-"} р.</td>
                          <td>{item.item.purchase_price || "-"} р.</td>
                          <td>
                            <div className="open_status_box">
                              <div className="open_status status_succes">
                                <p>{item.status}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="empty_block">
                        <td>Пусто</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <PaginationSecondary
                pageCount={dataLengthG ? Math.ceil(dataLengthG / 10) : 0}
                onPageChange={setCurrentPageG}
              />
            </div>
            {/* payments */}
            <div className="user_operations">
              <div className="user_operations_title">
                <p className="user_block_title">История пополнений</p>
                <div className="cases_top_togglers">
                  <button
                    className={
                      activeFilterPayments == ""
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsPayments("")}
                  >
                    <p>Все статусы</p>
                  </button>
                  <button
                    className={
                      activeFilterPayments == "create"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsPayments("create")}
                  >
                    <p>Создан</p>
                  </button>
                  <button
                    className={
                      activeFilterPayments == "expired"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsPayments("expired")}
                  >
                    <p>Отменен</p>
                  </button>
                  <button
                    className={
                      activeFilterPayments == "success"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsPayments("success")}
                  >
                    <p>Оплачен</p>
                  </button>
                  <button
                    className={
                      activeFilterPayments == "manually approved"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsPayments("manually approved")}
                  >
                    <p>Одобрен вручную</p>
                  </button>
                </div>
                <div className="users_search">
                  <SearchIcon />
                  <input type="text" placeholder="Поиск" />
                </div>
              </div>
              <div className="user_operations_table">
                <table className="user_table">
                  <thead>
                    <tr>
                      <th>
                        <button
                          className={
                            sortPayments == "id" || sortPayments == "-id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortPayments !== 'id') {
                              setSortPayments('id')
                            } 
                            if (sortPayments === 'id') {
                              setSortPayments('-id')
                            }
                          }}
                        >
                          <p>ID пополнения</p>
                          {sortPayments === 'id' ? <p>&uarr;</p> : sortPayments === '-id' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>Способ пополнения</th>
                      <th>
                        <button
                          className={
                            sortPayments == "created_at" || sortPayments == "-created_at" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortPayments !== 'created_at') {
                              setSortPayments('created_at')
                            } 
                            if (sortPayments === 'created_at') {
                              setSortPayments('-created_at')
                            }
                          }}
                        >
                          <p>Дата</p>
                          {sortPayments === 'created_at' ? <p>&uarr;</p> : sortPayments === '-created_at' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sortPayments == "sum" || sortPayments == "-sum" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortPayments !== 'sum') {
                              setSortPayments('sum')
                            } 
                            if (sortPayments === 'sum') {
                              setSortPayments('-sum')
                            }
                          }}
                        >
                          <p>Сумма</p>
                          {sortPayments === 'sum' ? <p>&uarr;</p> : sortPayments === '-sum' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sortPayments == "status" || sortPayments == "-status" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortPayments !== 'status') {
                              setSortPayments('status')
                            } 
                            if (sortPayments === 'status') {
                              setSortPayments('-status')
                            }
                          }}
                        >
                          <p>Статус</p>
                          {sortPayments === 'status' ? <p>&uarr;</p> : sortPayments === '-status' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userPayments && userPayments.length ? (
                      userPayments.map((item) => (
                        <tr key={item.id}>
                          <td className="td_id">{item.id || "-"}</td>
                          <td>{item.status || "-"}</td>
                          <td>{format(item.created_at, "yyyy-MM-dd HH:mm:ss")}</td>
                          <td>{item.sum} р.</td>
                          <td>
                            <div className="open_status_box">
                              <div className="open_status status_succes">
                                <p>-</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                        <tr className="empty_block">
                        <td>Пусто</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <PaginationSecondary
                pageCount={dataLengthG ? Math.ceil(dataLengthG / 10) : 0}
                onPageChange={setCurrentPage}
              />
            </div>
            {/* stats */}
            <div className="user_operations">
              <div className="user_operations_title">
                <p className="user_block_title">Статистика пользователя</p>
              </div>
              <div className="user_stats_blocks">
                <div className="user_stats_block">
                  <div className="user_stats_block_title">
                    <p>Депозитов за все время</p>
                  </div>
                  <div className="user_stats_block_amount">
                    <p>{user.all_debit} ₽</p>
                  </div>
                </div>
                <div className="user_stats_block">
                  <div className="user_stats_block_title">
                    <p>Вывел</p>
                  </div>
                  <div className="user_stats_block_amount">
                    <p>{user.all_output} ₽</p>
                  </div>
                </div>
              </div>
            </div>
            {/* inventar */}
            <div className="user_operations">
              <div className="user_operations_title">
                <p className="user_block_title">Инвентарь</p>
                <div className="cases_top_togglers">
                  <button
                    className={
                      activeFilterUserItems == ""
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterUserItems("")}
                  >
                    <p>Все статусы</p>
                  </button>
                  <button
                    className={
                      activeFilterUserItems == "active"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterUserItems("active")}
                  >
                    <p>На аккаунте</p>
                  </button>
                  <button
                    className={
                      activeFilterUserItems == "inactive"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterUserItems("inactive")}
                  >
                    <p>Продан</p>
                  </button>
                  <button
                    className={
                      activeFilterUserItems == "withdrawn"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterUserItems("withdrawn")}
                  >
                    <p>Выведен</p>
                  </button>
                </div>
                <div className="users_search">
                  <SearchIcon />
                  <input type="text" placeholder="Поиск" />
                </div>
              </div>
              <div className="user_operations_table">
                <table className="user_table">
                  <thead>
                    <tr>
                      <th>
                        <button
                          className={
                            sortUserItems == "id" || sortUserItems == "-id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortUserItems !== 'id') {
                              setSortUserItems('id')
                            } 
                            if (sortUserItems === 'id') {
                              setSortUserItems('-id')
                            }
                          }}
                        >
                          <p>ID товара</p>
                          {sortUserItems === 'id' ? <p>&uarr;</p> : sortUserItems === '-id' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sortUserItems == "item_name" || sortUserItems == "-item_name" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortUserItems !== 'item_name') {
                              setSortUserItems('item_name')
                            } 
                            if (sortUserItems === 'item_name') {
                              setSortUserItems('-item_name')
                            }
                          }}
                        >
                          <p>Товар</p>
                          {sortUserItems === 'item_name' ? <p>&uarr;</p> : sortUserItems === '-item_name' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sortUserItems == "item_price" || sortUserItems == "-item_price" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortUserItems !== 'item_price') {
                              setSortUserItems('item_price')
                            } 
                            if (sortUserItems === 'item_price') {
                              setSortUserItems('-item_price')
                            }
                          }}
                        >
                          <p>Сумма</p>
                          {sortUserItems === 'item_price' ? <p>&uarr;</p> : sortUserItems === '-item_price' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userItems && userItems.length ? (
                      userItems.map((item) => (
                        <tr key={item.id}>
                          <td className="td_id">{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.price} р.</td>
                        </tr>
                      ))
                    ) : (
                        <tr className="empty_block">
                        <td>Пусто</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <PaginationSecondary
                pageCount={dataLengthG ? Math.ceil(dataLengthG / 10) : 0}
                onPageChange={setCurrentPageI}
              />
            </div>
            {/* Inventory history */}
            <div className="user_operations">
              <div className="user_operations_title">
                <p className="user_block_title">История инвентаря</p>
                <div className="cases_top_togglers">
                  <button
                    className={
                      activeFilterItemsHistory == ""
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsHistory("")}
                  >
                    <p>Все статусы</p>
                  </button>
                  <button
                    className={
                      activeFilterItemsHistory == "active"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsHistory("active")}
                  >
                    <p>На аккаунте</p>
                  </button>
                  <button
                    className={
                      activeFilterItemsHistory == "inactive"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsHistory("inactive")}
                  >
                    <p>Продан</p>
                  </button>
                  <button
                    className={
                      activeFilterItemsHistory == "withdrawn"
                        ? "main_btn top_active_filter"
                        : "main_btn"
                    }
                    onClick={() => filterItemsHistory("withdrawn")}
                  >
                    <p>Выведен</p>
                  </button>
                </div>
              </div>
              <div className="user_operations_table">
                <table className="user_table">
                  <thead>
                    <tr>
                      <th>
                        <button
                          className={
                            sortItemsHistory == "id" || sortItemsHistory == "-id" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortItemsHistory !== 'id') {
                              setSortItemsHistory('id')
                            } 
                            if (sortItemsHistory === 'id') {
                              setSortItemsHistory('-id')
                            }
                          }}
                        >
                          <p>ID</p>
                          {sortItemsHistory === 'id' ? <p>&uarr;</p> : sortItemsHistory === '-id' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>
                        <button
                          className={
                            sortItemsHistory == "item_name" || sortItemsHistory == "-item_name" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortItemsHistory !== 'item_name') {
                              setSortItemsHistory('item_name')
                            } 
                            if (sortItemsHistory === 'item_name') {
                              setSortItemsHistory('-item_name')
                            }
                          }}
                        >
                          <p>Item ID</p>
                          {sortItemsHistory === 'item_name' ? <p>&uarr;</p> : sortItemsHistory === '-item_name' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>Название</th>
                      <th>Изображение</th>
                      <th>
                        <button
                          className={
                            sortItemsHistory == "item_price" || sortItemsHistory == "-item_price" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sortItemsHistory !== 'item_price') {
                              setSortItemsHistory('item_price')
                            } 
                            if (sortItemsHistory === 'item_price') {
                              setSortItemsHistory('-item_price')
                            }
                          }}
                        >
                          <p>Цена</p>
                          {sortItemsHistory === 'item_price' ? <p>&uarr;</p> : sortItemsHistory === '-item_price' ? <p>&darr;</p> : ''}
                        </button>
                      </th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userItemsHistory && userItemsHistory.length ? (
                      userItemsHistory.map((item) => (
                        <tr key={item.id}>
                          <td className="td_id">{item.id}</td>
                          <td>{item.item_id}</td>
                          <td>{item.name}</td>
                          <td>
                            <div className="">
                              <img className="td_img" src={item.image} alt={item.name} />
                            </div>
                          </td>
                          <td>{item.price} р.</td>
                          <td>
                            <div className="open_status_box">
                              <div className="open_status status_succes">
                                <p>{item.status}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                        <tr className="empty_block">
                        <td>Пусто</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default UserPage;
