import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import {format} from "date-fns";

import Pagination from "../../components/pagionation/pagination";
import {mainApi} from "../../components/utils/main-api";
import Snacbar from "../../components/snackbar/snackbar";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import useDebounce from "../../hooks/useDebounce.ts";

import {ReactComponent as SearchIcon} from "../../assets/icons/search-icon.svg";
import {ReactComponent as SelectedIcon} from "../../assets/icons/selected-icon.svg";
import "./promocodes.css";

function Promocodes() {
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [isSnackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [active, setActive] = useState(false);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText) => {
    setSnackbarVisible(true);
    setSnackbarText(snackText);
    setTimeout(() => {
      setSnackbarVisible(false);
    }, 2000);
  };

  const editPromocode = (id) => {
    navigate(`/edit-promocode/${id}`);
  };

  const toggleSelected = (data) => {
    const filteredSelectedItems = selected.some(
      (selected) => selected.id === data.id
    );
    if (filteredSelectedItems) {
      setSelected(selected.filter((item) => item.id !== data.id));
    } else {
      setSelected([...selected, data]);
    }
  };

  const toggleAllDataSelected = () => {
    if (selected.length === promocodes.length) {
      setSelected([]);
    } else {
      setSelected([...promocodes]);
    }
  };

  const filterItems = (type) => {
    setActive(false);
    setActiveFilter(type);
    setCurrentPage(0);
  };

  const getPromocodes = (limit, offset = 0, activeFilter, active, sort, search) => {
    mainApi
      .getPromos(limit, offset, activeFilter, active, sort, search)
      .then((res) => {
        setPromocodes(res.results);
        setDataLength(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };


  const deletePromocode = (id) => {
    mainApi
      .deletePromo(id)
      .then((res) => {
        console.log(res)
      })
      .catch((error) => {
        // console.log("error", error);
      });
    snackbarActions("Промокод удален!");
    setTimeout(() => {
      getPromocodes(limit.value, currentPage * limit.value, activeFilter, active, sort, search);
    }, 1200);
  };

  const deletePromocodes = () => {
    const bulkDestroy = selected.map((el) => el.id)
    mainApi
      .bulkDestroyPromoAction(bulkDestroy)
      .then((res) => {
        getPromocodes(limit.value, currentPage * limit.value, activeFilter, active, sort, search);
        snackbarActions(res.message);
        setSelected([]);
      })
      .catch((error) => {
        snackbarActions("Ошибка");
      });
  };

  const renderBonus = (summ, percent) => {
    if (summ) {
      return '+ ' + summ + '₽'
    } else {
      return '+ ' + percent + '%'
    }
  }

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getPromocodes(limit.value, currentPage * limit.value, activeFilter, active, sort, search);
  }, [currentPage, activeFilter, active, sort, limit, debouncedSearch]);

  return (
    <>
      <div className="template_page promocode_page">
        <div className="template_page_title">
          <h1>Промокоды</h1>
          <div className="top_cases_actions">
            <NavLink to="/create-promocode">
              <button className="main_btn add_case_btn main_btn_template">
                <p>Добавить промокод</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6663 8.66667H8.66634V12.6667H7.33301V8.66667H3.33301V7.33334H7.33301V3.33334H8.66634V7.33334H12.6663V8.66667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
        <div className="template_page_content">
          <div className="cases_wrapper">
            <div className="cases_top_togglers">
              <button className={
                activeFilter == "" && !active
                  ? "main_btn top_active_filter"
                  : "main_btn"
                }
                onClick={() => filterItems("")}
              >
                <p>Все промокоды</p>
              </button>
              <button className={
                activeFilter == "bonus" && !active
                  ? "main_btn top_active_filter"
                  : "main_btn"
                }
                onClick={() => filterItems("bonus")}
              >
                <p>Бонус ₽ к пополнению</p>
              </button>
              <button className={
                activeFilter == "balance" && !active
                  ? "main_btn top_active_filter"
                  : "main_btn"
                }
                onClick={() => filterItems("balance")}
              >
                <p>На баланс</p>
              </button>
              <button className={
                active
                  ? "main_btn top_active_filter"
                  : "main_btn"
                }
                onClick={() => setActive(true)}
              >
                <p>Показать только активные</p>
              </button>
            </div>
            <div className="cases_top_actions">
              {selected.length !==0 && (
                <button
                  className="main_btn main_btn_template_red"
                  onClick={deletePromocodes}
                >
                  <p>Массовое удаление</p>
                </button>
              )}
              <div className="users_search">
                <SearchIcon/>
                <input
                  type="text"
                  value={search}
                  placeholder="Поиск"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {promocodes && promocodes.length ? (
              <>
                <table className="cases_table ">
                  <thead>
                  <tr>
                    <td>
                      <button
                        className={
                          sort == "id" || sort == "-id" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                            if (sort !== 'id') {
                              setSort('id')
                            } 
                            if (sort === 'id') {
                              setSort('-id')
                            }
                          }}
                      >
                        <p>ID</p>
                        {sort === 'id' ? <p>&uarr;</p> : sort === '-id' ? <p>&darr;</p> : ''}
                      </button>
                    </td>
                    <td>
                      <button
                        className={
                          sort == "name" || sort == "-name" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                            if (sort !== 'name') {
                              setSort('name')
                            } 
                            if (sort === 'name') {
                              setSort('-name')
                            }
                          }}
                      >
                          <p>Название</p>
                          {sort === 'name' ? <p>&uarr;</p> : sort === '-name' ? <p>&darr;</p> : ''}
                      </button>
                    </td>
                    <td>
                      <button
                        className={
                          sort == "code_data" || sort == "-code_data" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                            if (sort !== 'code_data') {
                              setSort('code_data')
                            } 
                            if (sort === 'code_data') {
                              setSort('-code_data')
                            }
                          }}
                      >
                          <p>Код</p>
                          {sort === 'code_data' ? <p>&uarr;</p> : sort === '-code_data' ? <p>&darr;</p> : ''}
                      </button>
                    </td>
                    <td>
                      <button
                        className={
                          sort == "type" || sort == "-type" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                            if (sort !== 'type') {
                              setSort('type')
                            } 
                            if (sort === 'type') {
                              setSort('-type')
                            }
                          }}
                      >
                          <p>Категория</p>
                          {sort === 'type' ? <p>&uarr;</p> : sort === '-type' ? <p>&darr;</p> : ''}
                      </button>
                    </td>
                    <td>
                      Бонус
                      <button
                        className={
                          sort == "summ" || sort == "-summ" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                            if (sort !== 'summ') {
                              setSort('summ')
                            } 
                            if (sort === 'summ') {
                              setSort('-summ')
                            }
                          }}
                      >
                          <p>(Сумма)</p>
                          {sort === 'summ' ? <p>&uarr;</p> : sort === '-summ' ? <p>&darr;</p> : ''}
                      </button>
                      <button
                        className={
                          sort == "percent" || sort == "-percent" 
                            ? "td_btn active_td_btn"
                            : "td_btn"
                        }
                        onClick={() => {
                            if (sort !== 'percent') {
                              setSort('percent')
                            } 
                            if (sort === 'percent') {
                              setSort('-percent')
                            }
                          }}
                      >
                        <p>(Процент)</p>
                        {sort === 'percent' ? <p>&uarr;</p> : sort === '-percent' ? <p>&darr;</p> : ''}
                      </button>
                    </td>
                    <td>Кол-во активаций</td>
                    <td>
                      Ограничения
                      <button
                          className={
                            sort == "remaining_activations" || sort == "-remaining_activations" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'remaining_activations') {
                              setSort('remaining_activations')
                            } 
                            if (sort === 'remaining_activations') {
                              setSort('-remaining_activations')
                            }
                          }}
                        >
                          <p>(Лимит активаций)</p>
                          {sort === 'remaining_activations' ? <p>&uarr;</p> : sort === '-remaining_activations' ? <p>&darr;</p> : ''}
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            sort == "created_at" || sort == "-created_at" 
                              ? "td_btn active_td_btn"
                              : "td_btn"
                          }
                          onClick={() => {
                            if (sort !== 'created_at') {
                              setSort('created_at')
                            } 
                            if (sort === 'created_at') {
                              setSort('-created_at')
                            }
                          }}
                        >
                        <p>Дата создания</p>
                        {sort === 'created_at' ? <p>&uarr;</p> : sort === '-created_at' ? <p>&darr;</p> : ''}
                        </button>
                      </td>

                    <td>
                      <div className="select_all">
                        <div className="is_selected ml_55px">
                          {selected.length === promocodes.length ? (
                            <SelectedIcon onClick={toggleAllDataSelected}/>
                          ) : (
                            <div
                              className="not_selected_item"
                              onClick={toggleAllDataSelected}
                            ></div>
                          )}
                        </div>
                        {" "}
                        Выделить все
                      </div>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  {promocodes && promocodes.length
                    ? promocodes.map((promocode) => {
                      // const toDate = new Date(promocode.to_date);
                      // toDate.setHours(toDate.getHours() - 3);
                      return <tr key={promocode.id}>
                        <td>{promocode.id}</td>
                        <td>{promocode.name}</td>
                        <td>{promocode.code_data}</td>
                        <td>Бонус {promocode.type !== 'bonus' ? '₽' : '%'} к депозиту</td>
                        <td>{renderBonus(promocode.summ, promocode.percent)}</td>
                        <td>{promocode.activations}</td>
                        <td>
                          {promocode.to_date &&<div>
                            <b>До даты: </b><br/>
                            <span>{promocode.to_date && format(promocode.to_date, "yyyy-MM-dd HH:mm:ss")}</span>
                          </div>}
                          {promocode.remaining_activations && <div>
                            <b>Лимит активаций: </b>
                            <span>{promocode.remaining_activations}</span>
                          </div>}
                        </td>
                        <td>{format(promocode.created_at, "yyyy-MM-dd HH:mm:ss")}</td>

                        <td>
                          <div className="cases_table_actions">
                            <div className="cases_table_actions_list">
                              <div
                                title="редактировать"
                                className="cases_table_edit"
                                onClick={() => editPromocode(promocode.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M2.99902 17.2512V21.0012H6.74902L17.809 9.94125L14.059 6.19125L2.99902 17.2512ZM20.709 7.04125C21.099 6.65125 21.099 6.02125 20.709 5.63125L18.369 3.29125C17.979 2.90125 17.349 2.90125 16.959 3.29125L15.129 5.12125L18.879 8.87125L20.709 7.04125Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>

                              <div
                                title="удалить"
                                className="cases_table_delete"
                                onClick={() =>
                                  deletePromocode(promocode.id)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="is_selected ">
                              {selected.some(
                                (selected) => selected.id === promocode.id
                              ) ? (
                                <SelectedIcon
                                  onClick={() => toggleSelected(promocode)}
                                />
                              ) : (
                                <div
                                  className="not_selected_item"
                                  onClick={() => toggleSelected(promocode)}
                                ></div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    })
                    : ""}
                  </tbody>
                </table>
                <div className="cases_paginations">
                  <Pagination
                    pageCount={Math.ceil(dataLength / limit.value)}
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
                <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
              </>
            ) : (
              <p className="empty_error">Пусто</p>
            )}
          </div>
        </div>
      </div>

      {isSnackbarVisible ? (
        <Snacbar text={snackbarText}/>
      ) : (
        ""
      )}
    </>
  );
}

export default Promocodes;
